import React, { useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "./SmartPole.css";
import "./PageS.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../../Products/Catalog_Form/Catalogform";
import image from "../../assets/smartpole.png";
import icon from "../../assets/Point.svg";
import Header from "../../Header/Header";
import Form from "../../Form/Form";
import Footer from "../../Footer/Footer";
import India from "../../assets/India.svg";

const SmartPoleSurat = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  return (
    <>
      <Helmet>
        <title>
          Smart Poles Manufacturers in Surat| Innovating Urban Infrastructure{" "}
        </title>
        <meta
          name="description"
          content="As leading smart pole manufacturers Surat, we specialize in crafting cutting-edge solutions for urban development. Our smart poles integrate advanced technologies to enhance safety, connectivity, and sustainability. Explore our range of innovative products designed to transform urban spaces."
        />
        <meta
          name="keyword"
          content="Smart Poles Surat, Best smart pole in Surat, Smart pole manufacture in Surat, smart pole manufacture Surat, smart pole for smart city in Bengalore, Smart Pole Technology in Bangalore, Smart LED street lights in Surat, Emergency response system in Surat, safe city in Surat, smart cities in Surat."
        />
        <link rel="canonical" href="https://www.okulr.com/Smart-poles-Surat" />
      </Helmet>
      <Header />
      <section className="Productpage_Section">
        <div className="product_heading_pole">
          <h2>Ecopole - One pole, many Possibilities!</h2>
        </div>
        <div className="product_details">
          <p>
            <strong>Hey Surat!</strong> Ecopole is one of innovative and
            disruptive product in the market catering to smart cities,
            municipalities, urban landscapes, large residential complex,
            industrial parks and more. One product encompasses multiple devices
            integrating as one unified solution giving data to multiple agencies
            in the city. It is a perfect example of incredible design meeting
            the required functionality. Equipped with multiple intelligent
            devices such as AI cameras, smart lighting, weather stations, public
            WiFi nodes, emergency response system, solar power charging,
            electric vehicle charger and much more.{" "}
          </p>
        </div>
        <div className="Pole_Section">
          <div className="Image_Points">
            <div className="SPoint1">
              <span className="Spointer_text1">
                Integrated Solar Grid-Connect System
              </span>
              <img src={icon} alt="Point" className="pointer_img" />
            </div>
            <div className="SPoint2">
              <span className="Spointer_text2"> Cleverlite IoT Lights </span>
              <img src={icon} alt="Point" />
            </div>
            <div className="SPoint3">
              <img src={icon} alt="Point" />
              <span className="Spointer_text3">WiFi Hotspot</span>
            </div>
            <div className="SPoint4">
              <img src={icon} alt="Point" />
              <span className="Spointer_text4">Speaker</span>
            </div>
            <div className="SPoint5">
              <img src={icon} alt="Point" />
              <span className="Spointer_text5">LED Display</span>
            </div>
            <div className="SPoint6">
              <img src={icon} alt="Point" />
              <span className="Spointer_text6">Weather Station</span>
            </div>
            <div className="SPoint7">
              <img src={icon} alt="Point" />
              <span className="Spointer_text7">AI Cameras</span>
            </div>
            <div className="SPoint8">
              <img src={icon} alt="Point" />
              <span className="Spointer_text8">Emergency Response System</span>
            </div>
            <div className="SPoint9">
              <img src={icon} alt="Point" />
              <span className="Spointer_text9">Electric Vehicle Charger</span>
            </div>
            <img src={image} alt="SmartPoleimg" />
          </div>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li> Smart Cities </li>
              <li> Safe Cities </li>
              <li> Highways </li>
              <li> City Streets </li>
              <li> Ring roads </li>
              <li> Parks </li>
              <li> Gated Residential Communities </li>
            </ul>
          </div>
        </div>
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Data analytics"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default SmartPoleSurat;
