import React from "react";
import { useState } from "react";
import icon from "../../assets/Success.svg";
import icon7 from "../../assets/Cancelwhite.svg";
import icon6 from "../../assets/Cancel.svg";
import axios from "axios";
import "./Catalogform.css";

import img1 from "../../assets/Img1.svg";
import img2 from "../../assets/Img2.svg";
import img3 from "../../assets/Img3.svg";
import { BaseURL } from "../../BaseURL";

const Catalogform = ({ setCatalogForm1, CatalogName }) => {

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState('')
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
    CatalogName,
  });
  const [isLoading, setIsLoading] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError('Email is required');
      setIsEmailValid(false);
    } else if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
      setIsEmailValid(false);
    } else {
      setEmailError('');
      setIsEmailValid(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });

    if (name === 'Email') {
      validateEmail(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (
      [
        formValues.First_Name,
        formValues.Last_Name,
        formValues.Email,
        formValues.Phone_Number,
        formValues.Message,
      ].some((field) => field?.trim() === "")
    ) {
      setIsLoading(false);
      alert("Please fill out required fields.");
      return;
    }

    if (!isEmailValid) {
      setIsLoading(false);
      alert("Please enter a valid email address.");
      return;
    }

    setShowForm(false);

    try {

      await axios.post(`${BaseURL}/api/v1/users/CatalogForm`, formValues, {
        header: {
          "content type": "application.json",
        },
      });
      // const responseData = response.data;
      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
      setIsLoading(false);
      setShowPopup(true);
    } catch (error) {
      setIsLoading(false);
      console.log("error Submitting form", error);
      alert("An error occurred while submittng the form. Please try again");
    }
    // console.log(responseData);
    // console.log(formValues);
  };

  const handleFocus = (e) => {
    const label = e.target.previousElementSibling;
    label.classList.add("Focused");
  };
  const handleBlur = (e) => {
    const label = e.target.previousElementSibling;
    if (!e.target.value) {
      label.classList.remove("Focused");
    }
  };
  return (
    <>
      {showForm && (
        <>
          <div
            className="Overlay2"
            onClick={() => {
              setCatalogForm1(false);
            }}
          />
          <div className="Popup2">
            <div className="catalog_form">
              <div className="catalogform_heading">
                <img
                  src={icon7}
                  alt="Close"
                  className="CloseButton"
                  onClick={() => {
                    setShowPopup(false);
                    setCatalogForm1(false);
                  }}
                />
                <h3>WE WILL GET IN TOUCH</h3>
                <h5>
                  We will share the product catalog to your email entered here.
                </h5>
              </div>
              <form className="Catalogue_Form">
                <div className="Input_Holder">
                  <label className="Label">First Name*</label>
                  <input
                    type="text"
                    name="First_Name"
                    className="Input_Field"
                    value={formValues.First_Name}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="Input_Holder">
                  <label className="Label">Last Name*</label>
                  <input
                    type="text"
                    name="Last_Name"
                    className="Input_Field2"
                    value={formValues.Last_Name}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="Input_Holder">
                  <label className="Label">Phone Number*</label>
                  <input
                    type="text"
                    name="Phone_Number"
                    className="Input_Field2"
                    value={formValues.Phone_Number}
                    onChange={(event) => {
                      handleChange(event);
                      handleNumChangeM(event);
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="Input_Holder">
                  <label className="Label">E-Mail*</label>
                  <input
                    type="email"
                    name="Email"
                    className="Input_Field"
                    value={formValues.Email}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {emailError && (
                    <p style={{ color: 'red', 'textAlign': 'end', 'fontSize': '10px', 'background': '#ffffff' }}>{emailError}</p>
                  )}
                </div>
                <div className="Input_Holder">
                  <label className="Label">Your message*</label>
                  <textarea
                    name="Message"
                    className="Message"
                    value={formValues.Message}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="Submit_button">
                  {isLoading ? (
                    <>
                      <div className="Overlay2" />
                      <div className="Popup1">
                        <div className="Logo_Container">
                          <div className="Image">
                            <div className="Img1">
                              <img className="Spin1" src={img1} alt="" />
                            </div>
                            <div className="Img2">
                              <img className="Spin" src={img2} alt="" />
                            </div>
                            <div className="Img3">
                              <img src={img3} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="Form_Submit"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        Submit
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {showPopup && (
        <>
          <div className="Overlay2" />
          <div className="Popup">
            <img
              src={icon6}
              alt="Close"
              className="CloseButton"
              onClick={() => {
                setShowPopup(false);
                setCatalogForm1(false);
              }}
            />
            <div className="PContents">
              <img src={icon} alt="Popup" />
              <h3>Thank you!</h3>
              <p>We sent you the product catalog to your email.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Catalogform;
