import React, { useEffect, useRef, useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "./Page.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../Catalog_Form/Catalogform";
// import VideoPlayer from '../../VideoAnalytics/VideoPlayer';
import ReactPlayer from "react-player/lazy";
import { Waypoint } from "react-waypoint";
import India from "../../assets/India.svg";

const SurakshaNetra = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  const tabs = document.querySelector(".tabs");
  const playerRef = useRef(null);
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop < lastScrollTop) {
        if (tabs) {
          tabs.classList.remove("hidden");
        }
      }
      lastScrollTop = currentScrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleExitViewport = () => {
    try {
      if (playerRef.current.getInternalPlayer()) {
        playerRef.current.getInternalPlayer().pauseVideo();
      }
    } catch (error) {
      console.log("");
    }
  };
  const handleScroll = () => {
    const video = document.querySelector(".video_container");
    const tabs = document.querySelector(".tabs");
    video.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      tabs.classList.add("hidden");
    }, 100);
  };
  return (
    <>
      <Helmet>
        <title>Suraksha Netra - Secure your Home | Security for Homes</title>
        <meta
          name="description"
          content=" Safeguard your home and loved ones with our comprehensive home security solutions India. From smart surveillance to advanced alarm systems, ensure round-the-clock protection and peace of mind. Explore our suraksha netra security options today!"
        />
        <meta
          name="keyword"
          content="CCTV camera for home security, home camera system, best home security camera, CCTV home security, home cctv systems, cctv camera for residence, cctv for apartment, cctv camera for flats"
        />
        <link rel="canonical" href="https://www.okulr.com/suraksha-netra" />
      </Helmet>
      <section
        className="Productpage_Section"
        style={{ marginBottom: "100px" }}
      >
        <div className="product_heading">
          <h2>Suraksha Netra</h2>
          <h3>Your AI driven watchdog securing your loved ones and home</h3>
        </div>
        <div className="product_details">
          <p>
            There are homes, and there are CCTVs installed supposedly to protect
            these homes. The question is: How on earth these very homes are
            burgled? Because these CCTVs are brainless forensic devices that
            only records the video. It cannot make a difference between normal
            and violation incidents. Most of us are made to believe that just
            installing CCTVs will secure our homes, while the fact is even these
            CCTVs and NVR are stolen by burglars who very well know how to deal
            with these passive devices.
          </p>
        </div>
        <Waypoint onLeave={handleExitViewport}>
          <div className="video_container">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=Syn72xOu-nc"
              ref={playerRef}
              controls={true}
              width="100%"
              height="100%"
              onPlay={() => {
                handleScroll();
              }}
            />
          </div>
        </Waypoint>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              We introduce to you <strong>Suraksha Netra</strong>, an AI driven
              reactive security product that protects your homes and lives of
              your loved ones at all times. This is your watchdog that
              constantly keeps a vigil of your home from intruders 24/7 without
              your intervention or effort. Comes with a subscription model
              wherein you pay a minuscule monthly to safeguard your loved ones
              and property. Works with your mobile phones with our Suraksha
              Netra app for iOS and Android, with instant alerts and
              notifications driven to you with 2-way audio communication with
              the person at your door or premises. It also raises a loud audio
              alert with intrusion as a first level deterrent factor to thwart
              the incident even before it happens.
            </p>
          </div>
        </div>
        <div className="application_process">
          <ul>
            <li>
              <p>
                24/7 dedicated AI driven security for your home and offices.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Integrated intrusion detection for human presence within the
                marked area at your home.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Door breach detection with instant hooter alarm to prevent the
                crime happening.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Syncronised iOS and Android Apps for camera controls, alerts &
                scheduler for anytime, anywhere secure connectivity to your
                premises.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Instant alerts and notifications of intrusion and breach to your
                App.
              </p>
            </li>
            <hr />
            <li>
              <p>
                2-way secure audio with automated triggered call with human
                presence detection.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Customised region of interest to mark areas within your premises
                to be under constant vigil and watch.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Customised scheduler on your App to schedule different modes of
                vigil as per your needs.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Edge storage within the camera reducing the hassles of managing
                additional storage devices.
              </p>
            </li>
            <hr />
            <li>
              <p>
                No NVR, No cabling hassles! State-of-the-art WiFi cameras with
                edge intelligence working on our neural compute plaYorm.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Cameras integrated anti-vandal feature to ensure they’re not
                tampered or stolen.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Cameras with Pulse streamer alerting you and our support teams
                about camera downtimes for quicker troubleshooting and services.
              </p>
            </li>
            <hr />
            <li>
              <p>
                You can opt for optional additional dedicated long term storage
                space in our secure cloud.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Integrated with nearest police patrol & station for quick
                actioning to reach your premises.
              </p>
            </li>
          </ul>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>Homes</li>
              <li>Govt Offices</li>
            </ul>
          </div>
        </div>

        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div> */}

        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Face recognition technology"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default SurakshaNetra;
