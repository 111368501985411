import React, { useEffect, useRef, useState } from "react";
import "./Page.css";
import "./SeemaSuraksha.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../Catalog_Form/Catalogform";
import image from "../../assets/Perimetrion.png";
import image1 from "../../assets/Perimetrion1.png";
import image2 from "../../assets/Perimetrion2.png";
import icon from "../../assets/Point.svg";
import ReactPlayer from "react-player";
import { Waypoint } from "react-waypoint";
import India from "../../assets/India.svg";

const SeemaSuraksha = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  const tabs = document.querySelector(".tabs");
  const playerRef = useRef(null);
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop < lastScrollTop) {
        if (tabs) {
          tabs.classList.remove("hidden");
        }
      }
      lastScrollTop = currentScrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleExitViewport = () => {
    try {
      if (playerRef.current.getInternalPlayer()) {
        playerRef.current.getInternalPlayer().pauseVideo();
      }
    } catch (error) {
      console.log("");
    }
  };
  const handleScroll = () => {
    const video = document.querySelector(".video_container");
    const tabs = document.querySelector(".tabs");
    video.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      tabs.classList.add("hidden");
    }, 100);
  };
  return (
    <>
      <Helmet>
        <title>
          Perimeter Intrusion Security System India| Protecting Your Boundaries
        </title>
        <meta
          name="description"
          content=" Explore our security solutions for enhanced peace of mind. Safeguard your premises with our advanced Perimeter Intrusion Security System. Utilizing cutting-edge technology, we provide comprehensive protection against unauthorized access and intrusions."
        />
        <meta
          name="keyword"
          content="perimeter security solutions, perimeter security systems, Perimeter Intrusion Security,Perimeter security, Perimeter intrusion, perimeter alarm, Perimeter Intrusion Detection."
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/intelligent-perimeter-security-enforcement"
        />
      </Helmet>
      <section className="Productpage_Section">
        <div className="product_heading">
          <h2>Perimeter Smart Security System</h2>
        </div>
        <div className="product_details">
          <p>
            Large perimeters or boundaries are always a pain in the neck to
            watch over 24/7. Manpower security with passive cameras are not the
            perfect tools to keep intruders away from your large boundaries.
            Whether its high security boundaries or private properties,
            detecting and stopping intrusion is not easy. Seema Suraksha, our
            perimeter security enforcement is a well-trained AI tool to guard
            your large boundary area with its integrated accurate human
            detection and recognition, multilingual voice alerts, sirens, zoned
            active deterrence alerts and anti-tampering systems.
          </p>
        </div>
        <Waypoint onLeave={handleExitViewport}>
          <div className="video_container">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=embN4OJsaF4"
              ref={playerRef}
              controls={true}
              width="100%"
              height="100%"
              onPlay={() => {
                handleScroll();
              }}
            />
          </div>
        </Waypoint>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              False alarms is a nuisance for perimeter protection with alarms
              even for animals and birds on the perimeter areas. Cabled fiber
              perimeter protection raises and alarm even with the slightest
              shake in the cables, making it high unreliable especially for high
              security zones. Seema Suraksha is designed and built on our AI
              platform with edge analytics integrated with the cameras for
              purely human detection only. It is equipped with two sensitivity
              options wherein a breach in the boundary cross-over is a security
              threat and the other one with high sensitivity wherein a human
              presence anywhere near the boundary is also classified as a
              breach. The first level deterrence is an integrated siren with
              voice alerts that will buzz even before the breach happens thereby
              preventing the incident. Automated evidence snapshot with zoning
              helps people action instantly to nab the intruder.
            </p>
          </div>
        </div>
        <div className="application_process">
          <ul>
            <li>
              <p>Designed and built on AI platform</p>
            </li>
            <hr />
            <li>
              <p>Detects breach only on human detection</p>
            </li>
            <hr />
            <li>
              <p>
                Option to raise alarms even for animals especially for border
                security
              </p>
            </li>
            <hr />
            <li>
              <p>
                Zoning across the perimeter for large areas for better actioning
              </p>
            </li>
            <hr />
            <li>
              <p>
                Automated evidence photo and video capture from the breach zone
              </p>
            </li>
            <hr />
            <li>
              <p>Can integrate with any model and make IP cameras</p>
            </li>
            <hr />
            <li>
              <p>
                Two layered sensitivity levels for secure perimeter protection
              </p>
            </li>
            <hr />
            <li>
              <p>
                Inbuilt speaker for multi-lingual voice alerts to stop the
                intruder from breach
              </p>
            </li>
            <hr />
            <li>
              <p>Loud siren integrated with cameras for instant audio alert</p>
            </li>
            <hr />
            <li>
              <p>
                Integrated with central control room with breach snapshot and
                incident video for immediate actioning
              </p>
            </li>
          </ul>
        </div>
        <div className="Perimetrion_Image_Section">
          <div className="Image_Back">
            <div className="Image_Points">
              <div className="Point1">
                {/* <p>Smart Light Indicator</p> */}
                <span className="pointer_text1">Smart Light Indicator</span>
                <img src={icon} alt="Point" className="pointer_img" />
              </div>
              <div className="Point2">
                {/* <p>Integrated Hooter </p> */}
                <span className="pointer_text2">Integrated Hooter </span>
                <img src={icon} alt="Point" />
              </div>
              <div className="Point3">
                {/* <p>AI Edge Camera with Human detection & Breach alerts </p> */}
                <img src={icon} alt="Point" />
                <span className="pointer_text3">
                  AI Edge Camera with Human detection & Breach alerts{" "}
                </span>
              </div>
              <img src={image} alt="Perimetrion" />
            </div>
          </div>
          <div className="About_product seemaSuraksha_spacing">
            <h3>Zoning with cluster integration across the perimeter</h3>
          </div>
          <div className="BiImageSection">
            <div className="Image_One">
              <img src={image1} alt="Perimetrion" />
            </div>
            <div className="Divider"></div>
            <div className="Image_Two">
              <img src={image2} alt="Perimetrion" />
            </div>
          </div>
        </div>
        <div className="features seemaSuraksha_spacing">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>Govt buildings</li>
              <li>Secretariat </li>
              <li>Vidhana Sabha</li>
              <li>Raj Bhavan </li>
              <li>VIP Residences</li>
            </ul>
            <ul>
              <li>Prisons </li>
              <li>Correction Centers</li>
              <li>High Court</li>
              <li>Private Residences</li>
              <li>Bungalows</li>
            </ul>
            <ul>
              <li>Resorts</li>
              <li>Recreation Parks</li>
              <li>Large Apartments</li>
            </ul>
          </div>
        </div>

        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Intelligent perimeter security enforcement"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default SeemaSuraksha;
