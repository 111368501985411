import React from "react";
import { useEffect, useState } from "react";
import "./Header.css";
import logo1 from "../assets/Logo.svg";

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [showMenu, setMenu] = useState(false);
  const [isProductsActive, setIsProductsActive] = useState(false);
  const toggleMenu = () => {
    setIsOpened(!isOpened);
    setMenu(!showMenu);
  };
  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'visible';
    }

  }, [isOpened]);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const header = document.querySelector(".header");
    const navLinks = document.querySelectorAll(".nav-bar");
    const pointers = document.querySelectorAll(".pointer");

    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPath) {
        link.classList.add("active");
        if (link.getAttribute("href") === "/") {
          header.classList.add("home");
        } else {
          header.classList.remove("home");
        }
      }
    }, []);
    const navLinks1 = [
      "/face-recognition-technology",
      "/automated-traffic-enforcement-system",
      "/perimeter-smart-security-system",
      "/vehicle-telematics",
      "/smart-poles",
      "/patrol-vehicle-surveillance",
      "/ambulance-signal-free-corridor",
      "/smart-pole-solution"
    ];

    if (navLinks1.includes(currentPath)) {
      setIsProductsActive(true)
    }
    pointers.forEach((pointer) => {
      const dataHrefArray = pointer.getAttribute("data-href").split(" ");
      if (dataHrefArray.includes(currentPath)) {
        pointer.classList.add("active");
      }
    });

  });

  return (
    <header className="header">
      <div className="Header_components">
        <div className="HLeft">
          <a href="/">
            <img src={logo1} alt="Okular Techminds" />
          </a>
        </div>
        <div className="HRight">
          <div className="Hpointer">
            <a className="nav-bar" href="/">
              Home
            </a>
            <div className="pointer" data-href="/"></div>
          </div>
          <div className="Hpointer">
            <a
              className={`nav-bar ${isProductsActive ? "active" : ""}`}
              href=
              // "/products"
              "/face-recognition-technology"
            >
              Products
            </a>
            <div
              className="pointer"
              data-href=
              // "/products"
              "/face-recognition-technology /automated-traffic-enforcement-system /perimeter-smart-security-system /vehicle-telematics /smart-poles /patrol-vehicle-surveillance /ambulance-signal-free-corridor /smart-pole-solution"
            ></div>
          </div>
          <div className="Hpointer">
            <a className="nav-bar" href="/Video-Analytics">
              Video Analytics
            </a>
            <div className="pointer" data-href="/Video-Analytics"></div>
          </div>
          <div className="Hpointer">
            <a className="nav-bar" href="/about">
              About
            </a>
            <div className="pointer" data-href="/about"></div>
          </div>
          <div className="Hpointer">
            <a className="nav-bar" href="/careers">
              Career
            </a>
            <div className="pointer" data-href="/careers"></div>
          </div>
          <div className="Hpointer">
            <a className="nav-bar" href="/reachus">
              Reach us
            </a>
            <div className="pointer" data-href="/reachus"></div>
          </div>
        </div>
      </div>

      <div className="Mobile_Header">
        <a href="/">
          <img src={logo1} alt="Ockulr Techminds" />
        </a>
        <div className={`Horizontal_Menu`}>
          <div
            className={`wrapper-menu  ${isOpened ? "opened" : ""}`}
            onClick={toggleMenu}
          >
            <div className="line-menu half start"></div>
            <div className="line-menu"></div>
            <div className="line-menu half end"></div>
          </div>
        </div>
      </div>
      {showMenu && (
        <>
          <div className={`Overlay ${isOpened ? "opened" : ""}`} onClick={toggleMenu} />
          <div className="Mobile_Menu">
            <div className="Menu">
              <a
                className="nav-bar"
                href="/"
                onClick={() => {
                  setIsOpened(false);
                  setMenu(false);
                }}
              >
                Home
              </a>
              <a
                className={`nav-bar ${isProductsActive ? "active" : ""}`}
                href=
                // "/products"
                "/face-recognition-technology"
                onClick={() => {
                  setIsOpened(false);
                  setMenu(false);
                }}
              >
                Products
              </a><a
                className="nav-bar"
                href="/Video-Analytics"
                onClick={() => {
                  setIsOpened(false);
                  setMenu(false);
                }}
              >
                Video Analytics
              </a>
              <a
                className="nav-bar"
                href="/about"
                onClick={() => {
                  setIsOpened(false);
                  setMenu(false);
                }}
              >
                About
              </a>
              <a
                className="nav-bar"
                href="/careers"
                onClick={() => {
                  setIsOpened(false);
                  setMenu(false);
                }}
              >
                Career
              </a>
              <a
                className="nav-bar"
                href="/reachus"
                onClick={() => {
                  setIsOpened(false);
                  setMenu(false);
                }}
              >
                Reach us
              </a>
            </div>
          </div>
        </>
      )}
    </header>
  );
};

export default Header;
