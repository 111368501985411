import React, { useEffect, useRef, useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "./ANPR.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../../Products/Catalog_Form/Catalogform";
// import VideoPlayer from '../../VideoAnalytics/VideoPlayer';
import ReactPlayer from "react-player/lazy";
import { Waypoint } from "react-waypoint";
import India from "../../assets/India.svg";
import Header from "../../Header/Header";
import Form from "../../Form/Form";
import Footer from "../../Footer/Footer";

const ANPRGandhinagar = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  const tabs = document.querySelector(".tabs");
  const playerRef = useRef(null);
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop < lastScrollTop) {
        if (tabs) {
          tabs.classList.remove("hidden");
        }
      }
      lastScrollTop = currentScrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleExitViewport = () => {
    try {
      if (playerRef.current.getInternalPlayer()) {
        playerRef.current.getInternalPlayer().pauseVideo();
      }
    } catch (error) {
      console.log("");
    }
  };
  const handleScroll = () => {
    const video = document.querySelector(".video_container");
    video.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  return (
    <>
      <Helmet>
        <title>
          Vehicle Access Control System Gandhinagar| RFID & ANPR Technology
        </title>
        <meta
          name="description"
          content="Our ANPR Vehicle Access Control System provides secure and efficient management of vehicle entry and exit. Utilizing advanced technology such as RFID, ANPR, and barriers, our system ensures authorized access, enhances security, and streamlines traffic flow. Explore our comprehensive solutions for effective vehicle access control today Gandhinagar."
        />
        <meta
          name="keyword"
          content="vehicle access control Gandhinagar, vehicle gate access control systems Gandhinagar, vehicle access control barriers Gandhinagar, vehicle access control system Gandhinagar, rfid vehicle entry system Gandhinagar, rfid vehicle access control Gandhinagar, anpr access control Gandhinagar, vehicle access barriers Gandhinagar, rfid vehicle access control system Gandhinagar, vehicle access management system Gandhinagar, access control system for vehicles Gandhinagar, anpr access control systems Gandhinagar"
        />
        <link rel="canonical" href="https://www.okulr.com/ANPR-Gandhinagar" />
      </Helmet>
      <Header />
      <section className="Productpage_Section">
        <div className="product_heading_anpr">
          <h2>Vehicular Access Control</h2>
        </div>

        <div className="product_details">
          <p>
            <strong>Hey Gandhinagar!</strong>&nbsp; Regulating vehicle access
            has been traditionally a manual system with security guards donning
            the cap of responsibility of vehicle entry and exit. With the advent
            of AI, this is now an autonomous system that regulates vehicle
            access without a human interreference
          </p>
        </div>
        <Waypoint onLeave={handleExitViewport}>
          <div className="video_container">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=2tqjTk_eokc"
              ref={playerRef}
              controls={true}
              width="100%"
              height="100%"
              onPlay={() => {
                handleScroll();
              }}
            />
          </div>
        </Waypoint>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Combination of ANPR cameras, vehicle analytics, boom barriers and
              a unified dashboard does it all with ease. Moving away from manual
              system, OVACS ensures that right vehicle entry and exit enhancing
              the security for the premises. Technology combining efficiency,
              accuracy, and ease-of-use makes it the best option for automating
              your premises vehicle entry system with parking management too.
            </p>
          </div>
        </div>
        <div className="application_process">
          <ul>
            <li>
              <p>ANPR cameras for entry and exit gates</p>
            </li>
            <hr />
            <li>
              <p>Vehicle screening, number plate recognition</p>
            </li>
            <hr />
            <li>
              <p>
                Face detection/recognition of driver, vehicle type
                classification, whitelisting and blacklisting of vehicles
              </p>
            </li>
            <hr />
            <li>
              <p>
                Integrated with boom barriers or bollards for automated
                regulation
              </p>
            </li>
            <hr />
            <li>
              <p>
                Unified dashboard with plethora of graphical and data reports
                for decision makers.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Option to integrate payment gateway integration for automated
                parking system.
              </p>
            </li>
            <hr />
            <li>
              <p>
                iOS & Android Apps for authorised users for booking parking
                slots, parking guidance system.
              </p>
            </li>
            <hr />
            <li>
              <p>
                Optional robotic under vehicle scanning of vehicles underneath.
              </p>
            </li>
          </ul>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt_anpr">
            <ul>
              <li>Shopping malls</li>
              <li>Tech parks</li>
              <li>Five-star hotels & resorts</li>
              <li>Govt Secretariats</li>
            </ul>
            <ul>
              <li>Residential complex</li>
              <li>Citywide parking</li>
              <li>Railway parking complex</li>
              <li>Airport parking complex</li>
            </ul>
            <ul>
              <li>Office complex and buildings</li>
              <li>Industrial parks</li>

              {/* <li>Camera Anti Tampering</li> */}
            </ul>
            <ul>
              <li>Defence</li>
              <li>critical infrastructure buildings</li>
            </ul>
          </div>
        </div>
        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div> */}

        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Face recognition technology"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default ANPRGandhinagar;
