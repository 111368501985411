import React, { useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "./Page.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../Catalog_Form/Catalogform";
import SignalFree from "../../assets/SignalFree.jpg";
import India from "../../assets/India.svg";

const SignalFreeCorridor = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  return (
    <>
      <Helmet>
        <title>
          Ambulance Signal-Free Corridors | Rapid Emergency Response
        </title>
        <meta
          name="description"
          content=" Ensure swift emergency response with dedicated signal-free corridors for ambulances. Our solutions prioritize ambulance passage, minimizing response times and saving lives. Explore our innovative approach to emergency healthcare infrastructure today."
        />
        <meta
          name="keyword"
          content="signal free corridors, Ambulance signal free corridors, okulr."
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/ambulance-signal-free-corridor"
        />
      </Helmet>
      <section className="Productpage_Section">
        <div className="product_heading">
          <h2> Ambulance Signal-free corridor</h2>
        </div>
        <div className="product_details">
          <p>
            There are traffic jams in almost major cities in India, and this
            leads to a problem every ambulance faces – Stuck in traffic jams
            with critical patients onboard. More than 70% of the ambulances are
            stuck in these traffic jams in most of the major cities. WHO says
            that the chances of life saving increases to over 90% if the patient
            reaches the hospital within the golden hour, which is indeed a
            challenge today.
          </p>
        </div>
        <div className="video_container">
          <img src={SignalFree} alt="" loading="lazy" />
        </div>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Technology can indeed resolve this problem, making a signal-free
              corridors for the ambulance. Our flagship product Ambulance
              Signal-free Corridor (ASC) is a state-of-the-art autonomous
              technology that combines IoT, AI and Data analytics to automate
              the signals to provide a signal0free passage to ambulance thereby
              saving lives. This is a lifesaving product, which when implemented
              eases the hassles of transit for ambulance in the city. Hospitals
              and ambulances are mapped together using an application that is
              used by patients to use the ambulance services. Every ambulance is
              equipped with our IoT device that communicates with signals when
              they’re 500 mtrs to 1 kms away from the signal. With an
              authentication protocol, the signals are opened for smooth passage
              to the ambulance.
            </p>
          </div>
        </div>
        <div className="application_process SignalFree_application_process">
          <ul>
            <li>
              <p>Mapping of ambulance and hospitals in the city</p>
            </li>
            <hr />
            <li>
              <p>Unique IoT device authentication for every ambulance</p>
            </li>
            <hr />
            <li>
              <p>
                Autonomous system for automated operations of signals with the
                presence of ambulance in the vicinity
              </p>
            </li>
            <hr />
            <li>
              <p>
                Smart App on iOS and Android for patients, hospitals and
                ambulance on a unified platform for ease-of-use
              </p>
            </li>
            <hr />
            <li>
              <p>Log reports of every ambulance movements within the city</p>
            </li>
            <hr />
            <li>
              <p>
                Eliminates fake usage of ambulance without the patient in the
                ambulance
              </p>
            </li>
          </ul>
        </div>
        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt=""
            />
          </button>
        </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Virtual walkthrough"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default SignalFreeCorridor;
