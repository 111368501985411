import React, { useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "./Page.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../Catalog_Form/Catalogform";
import India from "../../assets/India.svg";
import SurakshaNetraimg from "../../assets/SurakshaNetra.jpg";

const PatrolSurveillance = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  return (
    <>
      <Helmet>
        <title>
          Patrol Vehicle Surveillance Solutions | Enhancing Law Enforcement
        </title>
        <meta
          name="description"
          content="Empower law enforcement agencies with our advanced patrol vehicle surveillance solutions. Utilizing cutting-edge technology, our systems offer real-time monitoring, video analytics, and situational awareness to ensure safer communities. Explore our comprehensive solutions for effective law enforcement today."
        />
        <meta
          name="keyword"
          content="vehicle surveillance, vehicle camera monitoring system, vehicle surveillance system, police mobile surveillance cameras, vehicle camera security system"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/patrol-vehicle-surveillance"
        />
      </Helmet>
      <section className="Productpage_Section">
        <div className="product_heading">
          <h2>Patrol vehicle onboard surveillance</h2>
        </div>
        <div className="product_details">
          <p>
            Police patrol vehicles are meant to be keeping an eye on the streets
            and to rush to crime scenes within the city. Police patrol vehicles
            also assists in high security VIP convoys too that requires
            preventive security systems.
          </p>
        </div>
        <div className="video_container">
          <img src={SurakshaNetraimg} alt="" loading="lazy" />
        </div>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Suraksha Netra is your eye with intelligence designed and built on
              our AI platform to equip police patrol vehicles to keep a
              dedicated watch on the streets. Cameras, in-vehicle display,
              onboard storage, edge video analytics combines to give police the
              tool to analyse the happening on the streets better. This also
              helps in monitoring the surrounding while in the VIP convoys
              ensuring heightened security while in transit. These tools are
              absolutely necessary in crowd monitoring, riot management, large
              event or campaign monitoring.
            </p>
          </div>
        </div>
        <div className="application_process">
          <ul>
            <li>
              <p>Onboard AI PTZ and fixed lens cameras</p>
            </li>
            <hr />
            <li>
              <p>
                In-vehicle rugged display for police to watch the streets while
                in the vehicle
              </p>
            </li>
            <hr />
            <li>
              <p>Onboard storage device to record the happenings</p>
            </li>
            <hr />
            <li>
              <p>
                Face recognition, crowd detection, people & vehicle tracking
                video analytics
              </p>
            </li>
            <hr />
            <li>
              <p>Real time connectivity to central police control room</p>
            </li>
          </ul>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>Applications</li>
              <li>Police patrol vehicles</li>
              <li>VIP convoy vehicles</li>
            </ul>
          </div>
        </div>
        {/* <div>
                    <button type="button" className='download_button' onClick={() => { setCatalogForm1(true) }}>Dowload catalogue<img src={cloudDownload} alt="" /></button>
                </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Automated vehicle access control"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default PatrolSurveillance;
