import React, { useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "./Page.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../Catalog_Form/Catalogform";
import VehicleTelematicsimg from "../../assets/VehicleTelematics.jpg";
import India from "../../assets/India.svg";

const VehicleTelematics = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  return (
    <>
      <Helmet>
        <title>Vehicle Telematics Solutions | Optimize Fleet Management</title>
        <meta
          name="description"
          content="Public buses, cargo transportation vehicles, critical material transportation systems, ambulance, oil tankers require added safety measures to ensure road safety. Our vehicle telematics product is designed & built on our AI platform to bring in whole new dimension to ensure safety of people and cargo."
        />
        <meta
          name="keyword"
          content=" vehicle telematics solutions, telematics vehicle tracking, telematics fleet management, vehicle telematics, vehicle telematics system, telemetrics tracking, truck telematics system driver alert system, driver drowsiness alert"
        />
        <link rel="canonical" href="https://www.okulr.com/vehicle-telematics" />
      </Helmet>
      <section className="Productpage_Section">
        <div className="product_heading">
          <h2>Vehicle Telematics</h2>
        </div>
        <div className="product_details">
          <p>
            Public buses, cargo transportation vehicles, critical material
            transportation systems, ambulance, oil tankers require added safety
            measures to ensure road safety, reduce accidents and loss of lives.
            Monitoring driver to ensure passenger safety is of utmost
            importance. Thanks to AI, this can be done with ease.
          </p>
        </div>
        <div className="video_container">
          <img src={VehicleTelematicsimg} alt="" loading="lazy" />
        </div>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Our vehicle telematics product is designed and built on our AI
              platform to bring in whole new dimension to ensure safety of
              people and cargo. It is equipped with a dashcam integrated with
              inbuilt edge video analytics to monitor, track and alert driver
              and the concerned authorities of the driving pattern of the
              driver. It integrates the vehicle to the central command center
              for real time monitoring, tracking and alert management. Inbuilt
              with onboard storage while in transit, the data dump will happen
              on the completion of the journey at the designated control rooms.
            </p>
          </div>
        </div>
        <div className="bulletpoints_container">
          <div className="bullet_point">
            <p>Driver drowsiness alerts</p>
          </div>
          <div className="bullet_point">
            <p>Mobile phone usage while driving</p>
          </div>
          <div className="bullet_point">
            <p>Collision alerts</p>
          </div>
          <div className="bullet_point">
            <p>Face recognition</p>
          </div>
          <div className="bullet_point">
            <p>Driving pattern recognition</p>
          </div>
          <div className="bullet_point"></div>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>Interstate & intercity buses</li>
              <li>Cargo trucks</li>
              <li>Oil tankers</li>
              <li> Ambulance</li>
              <li> Cabs </li>
            </ul>
          </div>
        </div>
        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"ANPR cameras"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default VehicleTelematics;
