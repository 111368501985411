import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
//import cloudDownload from "../../assets/cloudDownload.svg";
import Catalogform from "../../Products/Catalog_Form/Catalogform.js";
import ReactPlayer from "react-player";
import { Waypoint } from "react-waypoint";
import Header from "../../Header/Header.js";
import Footer from "../../Footer/Footer.js";
import Form from "../../Form/Form.js";
import India from "../../assets/India.svg";

const ITMSPimpareChinchwad = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  const playerRef = useRef(null);

  const handleExitViewport = () => {
    try {
      if (playerRef.current.getInternalPlayer()) {
        playerRef.current.getInternalPlayer().pauseVideo();
      }
    } catch (error) {
      console.log("");
    }
  };
  const handleScroll = () => {
    const video = document.querySelector(".video_container");
    video.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  return (
    <>
      <Helmet>
        <title>
          Intelligent Traffic Management System(ITMS)in Pimpare-Chinchwad
        </title>
        <meta
          name="description"
          content="Our Intelligent Traffic Management System utilizes advanced technology to optimize traffic flow, reduce congestion, and enhance safety on roadways in Pimpare Chinchwad. With real-time data analytics, adaptive signals, and smart sensors, our system improves efficiency and supports sustainable urban mobility. "
        />
        <meta
          name="keyword"
          content="Traffic Safety Enforcement in Pimpare Chinchwad, Automated Road Traffic Safety in Pimpare Chinchwad, road safety enforcement in Pimpare Chinchwad, Enhancing Road Safety in Pimpare Chinchwad"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/ITMS-Pimpare-Chinchwad"
        />
      </Helmet>
      <Header />
      <section className="ITMSProductpage_Section">
        <div className="ITMSproduct_heading_s">
          <h2> Intelligent Traffic Management System</h2>
        </div>
        <div className="ITMSproduct_details">
          <p>
            <span id="city">Hey Pimpare and Chinchwad!</span> Managing
            burgeoning traffic today in Indian cities is indeed a gargantuan
            task for traffic police. With increasing vehicular population,
            enforcement is of utmost importance to increase road safety, and to
            save lives considering 4.61 lakhs accidents reported in 2022 in
            India as per data from MORTH. Using AI to regulate road traffic,
            increase automated smart enforcement and educate road safety to
            users is the need of the hour. ATES is our traffic enforcement
            product designed for India, by Indians, and proudly Made in India.
          </p>
        </div>
        <Waypoint onLeave={handleExitViewport}>
          <div className="video_container">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=HJhxt8ix_wQ"
              ref={playerRef}
              controls={true}
              width="100%"
              height="100%"
              onPlay={() => {
                handleScroll();
              }}
            />
          </div>
        </Waypoint>
        <div className="About_product">
          <h3>About the Product</h3>
          <p>
            ATES encompasses all aspects of traffic enforcement starting from
            traffic violation detection & capture to issuing eChallans to
            traffic offenders on an automated AI platform. ATES is designed and
            developed for Indian traffic volume, scenarios and regulations. ATES
            works with multiple camera models and makes, making it a retrofit
            device ensuring our customers need not make new investments on their
            cameras. It integrates multiple cities, multiple cameras with
            multiple video analytics use cases into one single unified platform
            making it easier for our customers to manage their traffic
            management efficiently and easily.
          </p>
        </div>
        <div className="bulletpoints_container">
          <div className="bullet_point">
            <p>Traffic violation uses cases in ATES</p>
          </div>
          <div className="bullet_point">
            <p>Indian number plate recognition</p>
          </div>
          <div className="bullet_point">
            <p>No-helmet driving detection</p>
          </div>
          <div className="bullet_point">
            <p>Seat-belt violation</p>
          </div>
          <div className="bullet_point">
            <p>Over speeding detection</p>
          </div>
          <div className="bullet_point">
            <p>Traffic jump violation detection</p>
          </div>
          <div className="bullet_point">
            <p>No-parking zone violation</p>
          </div>
          <div className="bullet_point">
            <p>Vehicle type classification</p>
          </div>
          <div className="bullet_point">
            <p>Vehicle counting</p>
          </div>
          <div className="bullet_point">
            <p>Vehicle whitelisting and blacklisting</p>
          </div>
          <div className="bullet_point">
            <p>Wrong-way driving violation</p>
          </div>
          <div className="bullet_point">
            <p>Triple riding detection</p>
          </div>
          <div className="bullet_point">
            <p>Lane change alerts</p>
          </div>
          <div className="bullet_point">
            <p>Vaahan integration</p>
          </div>
          <div className="bullet_point">
            <p>eChallan integration</p>
          </div>
        </div>
        <div className="About_product">
          <p>
            With ATES traffic offenders can get eChallans as SMS messages with
            link to see their violation and also pay the fines with integrated
            payment gateway channels. The inbuilt data analytics keeps a track
            of repeat traffic offenders in each of the zones, ensuring the
            traffic police is aware of these offenders.
          </p>
        </div>
        <div className="About_product">
          <h3>Predictive Traffic Analytics</h3>
          <div>
            <p>
              This is an absolute need for major cities in India with traffic
              jams everywhere. Analysing traffic density and volumes, our
              predictive models in ATES helps commuters with advance information
              of traffic jams in key arterial roads in the city to avoid further
              clogging and helping them reroute themselves to reach their
              destinations on time. Traffic Sahay apps for commuters will help
              in knowing the best routes to take, traffic density information
              across major roads in the city and more. Predictive analytics
              gives police with abundant information about traffic volume
              patterns, peak and off-peak hours, routes and alternate rerouting
              and more for better traffic management and smoother traffic flow
              across the city.
            </p>
          </div>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>City roads</li>
              <li>Ring roads</li>
              <li>State highways</li>
              <li>National highways</li>
            </ul>
          </div>
        </div>
        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Automated road traffic safety enforcement"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default ITMSPimpareChinchwad;
