import React, { useState } from "react";
import "./Career.css";
import { Helmet } from "react-helmet-async";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Form from "../Form/Form";
import image from "../assets/CareerPhoto.jpg";
import icon from "../assets/Knowledge.svg";
import icon1 from "../assets/Office.svg";
import icon2 from "../assets/Group.svg";
import icon3 from "../assets/customer.svg";
import image1 from "../assets/CareerPhotomob.jpg";
import Resumeform from "./Resume_Form/Resumeform";

const Career = () => {
  const [ResumeForm1, setResumeForm1] = useState(false);
  const [Jobname, setJobname] = useState();

  return (
    <div>
      <Helmet>
        <title>
          Careers | Jobs in Computer Vision, Full-stack, Android & iOS job
          openings
        </title>
        <meta
          name="description"
          content="Explore exciting career opportunities and become part of our innovative team. Join us in shaping the future!"
        />
        <meta
          name="keyword"
          content="Careers , Latest Jobs Opening, Job Opening, Current Job Opening, deep learning, image processing, video analytics developer, Yolo, OpenCV, AI Internship, Computer Vision, python developer, QT developer, Flask developer, Full stack developer, C++, C# developer. "
        />
        <link rel="canonical" href="https://www.okulr.com/careers" />
      </Helmet>
      <Header />
      <div className="Career_components">
        <div className="Career_Image">
          <img
            src={image}
            alt="Okulr Techminds"
            loading="lazy"
            className="destop_career"
          />
          <img
            src={image1}
            alt="Okulr Techminds"
            loading="lazy"
            className="mobimg_career"
          />
        </div>
        <div className="Career_Heading">
          <h2>Join us to build disruptive products in AI.</h2>
        </div>
        <div className="Career_Subheading">
          <p>
            A pinch of passion, curiosity mixed with courage led us here. The
            motto has been to make sense of seeing with our vision computing
            platform. You’ll be joining a team of curious techy Okulrs,
            constantly innovating, and inventing disruptive tech to make cameras
            a decision- making tool from a passive video recording tool.
          </p>
          <p>
            Knowledge drives growth, Growth drives money! For us, Knowledge is
            supreme and supersedes everything. We’re young, energetic,
            innovative, fun, brainy Okulrs, persistently staying hungry for
            learning. Everything we do here starts with research. Join us for
            creating disruptive vision computing products that touch lives of
            people around, making our society we live better.
          </p>
        </div>
        <div className="Benefits_Section">
          <div className="Benefits_Section_1">
            <div className="Career_Heading_1">
              <h2>
                Knowledge driven,
                <span>
                  <br />
                </span>{" "}
                Tech Minds
              </h2>
            </div>
            {/* <div className="Career_Subheading_1">
              <p>
                We appreciate the great work that our team does everyday. That’s
                why we offer an excellent benefits package that ensures each
                person has what they need to succeed inside and outside of the
                office.
              </p>
            </div> */}
          </div>
          <div className="Benefits_Section_2">
            <div className="LeftBenefit">
              <div className="Benefit">
                <img src={icon} className="Work" alt="Work" />
                <h3>Knowledge is supreme</h3>
                <p>
                  This is one treasure we build every minute, every day that
                  never depletes. Every product we’ve created is a result of
                  research that involves deep knowledge exploration. We’re proud
                  about this acquisition.
                </p>
              </div>
              <div className="Benefit">
                <img src={icon2} className="Group" alt="Group" />
                <h3>Team spirit</h3>
                <p>
                  We’re believers in together we walk long. While every
                  individual contributes, we at Okulr work as a team to
                  accomplish the unthinkable. Life is a relay race, and we know
                  that alone we cant accomplish anything. Everyone at Okulr, our
                  minds and hearts are connected for a single motive – Achieve.
                </p>
              </div>
            </div>
            <div className="RightBenefit">
              <div className="Benefit">
                <img src={icon1} className="Office" alt="Office" />
                <h3>Creativity through empowerment</h3>
                <p>
                  We stand for empowerment, as any human being who is empowered
                  has the freedom to think, create and take responsibility of
                  their creation. Innovation stems from empowerment. Our people
                  are empowered ones who has the freedom to innovate every step
                  of their work.
                </p>
              </div>
              <div className="Benefit">
                <img src={icon3} className="Group" alt="Group" />
                <h3>Customer Delight</h3>
                <p>
                  All these and our efforts lead to one motto – Customer
                  Delight. We’re a deep customer focused and customer centric
                  people. Every product we create resonates this with the
                  ultimate reward that our customer is happy enough with our
                  products and solutions. Customer delight is the outcome of our
                  knowledge, empowerment & creativity and team spirit.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Quote_Section">
          <div className="Quote">
            <h2>“</h2>
            <h3>Apply with us to join the AI revolution</h3>
            <h2 style={{ marginBottom: "10px" }}>”</h2>
          </div>
          <p>
            Go ahead, send us your resume with a 2min intro video for the
            positions listed below. You don’t have a video, it's okay. Record
            one and upload it's link in the apply form given below. A confident
            person is better than a skilled person! We’re more interested to see
            how you represent yourself than a boring resume!
          </p>
        </div>
        <div className="Table_Section">
          <table>
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Years of Experience</th>
                <th>Job Vacancy</th>
                <th>Job Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Full stack Developer</td>
                <td>0-5 years</td>
                <td>Multiple Positions</td>
                <td>On-site</td>
                <td>
                  <span
                    onClick={() => {
                      setResumeForm1(true);
                      setJobname("Full Stack Developer");
                    }}
                  >
                    Apply
                  </span>
                </td>
              </tr>
              <tr>
                <td>Computer Vision Engineer</td>
                <td>1-2 years</td>
                <td>Multiple Positions</td>
                <td>On-site</td>
                <td>
                  <span
                    onClick={() => {
                      setResumeForm1(true);
                      setJobname("Computer Vision Engineer");
                    }}
                  >
                    Apply
                  </span>
                </td>
              </tr>
              <tr>
                <td>UI/UX Designer</td>
                <td>0-3 years</td>
                <td>Multiple Positions</td>
                <td>On-site</td>
                <td>
                  <span
                    onClick={() => {
                      setResumeForm1(true);
                      setJobname("UI/UX Designer");
                    }}
                  >
                    Apply
                  </span>
                </td>
              </tr>
              <tr>
                <td className="Last_Row">Business Development Associate</td>
                <td className="Last_Row">1-3 years</td>
                <td className="Last_Row">Multiple Positions</td>
                <td className="Last_Row">On-site</td>
                <td className="Last_Row">
                  <span
                    onClick={() => {
                      setResumeForm1(true);
                      setJobname("Business Development Associate");
                    }}
                  >
                    Apply
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {ResumeForm1 && (
        <>
          <Resumeform setResumeForm1={setResumeForm1} JobName={Jobname} />
        </>
      )}
      <Form />
      <Footer />
    </div>
  );
};

export default Career;
