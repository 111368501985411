import "./App.css";
import { useEffect } from "react";
import Homepage from "./Homepage/Homepage.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Comingsoon from "./ComingSoon/Comingsoon.js";
import Reachus from "./Reachus/Reachus.js";
import Career from "./Career/Career.js";
import ProductsLayout from "./ProductsLayout";
import { HelmetProvider } from "react-helmet-async";
import FaceTech from "./Products/ProductPages/FaceTech.js";
import SeemaSuraksha from "./Products/ProductPages/SeemaSuraksha.js";
import VehicleTelematics from "./Products/ProductPages/VehicleTelematics.js";
import SurakshaNetra from "./Products/ProductPages/SurakshaNetra.js";
import PatrolSurveillance from "./Products/ProductPages/PatrolSurveillance.js";
import SignalFreeCorridor from "./Products/ProductPages/SignalFreeCorridor.js";
import MahilaSuraksha from "./Products/ProductPages/MahilaSuraksha.js";
import TrafficEnforcement from "./Products/ProductPages/TrafficEnforcement.js";
import SmartPole from "./Products/ProductPages/SmartPole.js";
import About from "./About/About.js";
import VideoAnalytics from "./VideoAnalytics/VideoAnalytics.js";
import FacerecognitioncompanyBengaluru from "./Landing Page/Face Recognition Company/face-recognition-company-Bengaluru.js";
import FacerecognitioncompanyAhmedabad from "./Landing Page/Face Recognition Company/face-recognition-company-Ahmedabad.js";
import FacerecognitioncompanyBhopal from "./Landing Page/Face Recognition Company/face-recognition-company-Bhopal.js";
import FacerecognitioncompanyBhuvaneshwar from "./Landing Page/Face Recognition Company/face-recognition-company-Bhuvaneshwar.js";
import FacerecognitioncompanyChandigarh from "./Landing Page/Face Recognition Company/face-recognition-company-Chandigarh.js";
import FacerecognitioncompanyChennai from "./Landing Page/Face Recognition Company/face-recognition-company-Chennai.js";
import FacerecognitioncompanyDelhi from "./Landing Page/Face Recognition Company/face-recognition-company-Delhi.js";
import FacerecognitioncompanyHyderabad from "./Landing Page/Face Recognition Company/face-recognition-company-Hyderabad.js";
import FacerecognitioncompanyJaipur from "./Landing Page/Face Recognition Company/face-recognition-company-Jaipur.js";
import FacerecognitioncompanyKochi from "./Landing Page/Face Recognition Company/face-recognition-company-Kochi.js";
import FacerecognitioncompanyMangalore from "./Landing Page/Face Recognition Company/face-recognition-company-Mangalore.js";
import FacerecognitioncompanyMumbai from "./Landing Page/Face Recognition Company/face-recognition-company-Mumbai.js";
import FacerecognitioncompanyPatna from "./Landing Page/Face Recognition Company/face-recognition-company-Patna.js";
import FacerecognitioncompanyPune from "./Landing Page/Face Recognition Company/face-recognition-company-Pune.js";
import FacerecognitioncompanyKolkata from "./Landing Page/Face Recognition Company/face-recognition-company-Kolkata.js";
import FacerecognitioncompanyAmaravati from "./Landing Page/Face Recognition Company/face-recognition-company-Amaravati.js";
import FacerecognitioncompanyItanagar from "./Landing Page/Face Recognition Company/face-recognition-company-Itanagar.js";
import FacerecognitioncompanyDispur from "./Landing Page/Face Recognition Company/face-recognition-company-Dispur.js";
import FacerecognitioncompanyGandhinagar from "./Landing Page/Face Recognition Company/face-recognition-company-Gandhinagar.js";
import FacerecognitioncompanyShimla from "./Landing Page/Face Recognition Company/face-recognition-company-Shimla.js";
import FacerecognitioncompanyRanchi from "./Landing Page/Face Recognition Company/face-recognition-company-Ranchi.js";
import FacerecognitioncompanyThiruvananthapuram from "./Landing Page/Face Recognition Company/face-recognition-company-Thiruvananthapuram.js";
import FacerecognitioncompanyImphal from "./Landing Page/Face Recognition Company/face-recognition-company-Imphal.js";
import FacerecognitioncompanyLucknow from "./Landing Page/Face Recognition Company/face-recognition-company-Lucknow.js";
import FacerecognitioncompanyMysore from "./Landing Page/Face Recognition Company/face-recognition-company-Mysore.js";
import FacerecognitioncompanyHubliDharwad from "./Landing Page/Face Recognition Company/face-recognition-company-Hubli-Dharwad.js";
import FacerecognitioncompanyBelgaum from "./Landing Page/Face Recognition Company/face-recognition-company-Belgaum.js";
import FacerecognitioncompanyGulbarga from "./Landing Page/Face Recognition Company/face-recognition-company-Gulbarga.js";
import FacerecognitioncompanyBellary from "./Landing Page/Face Recognition Company/face-recognition-company-Bellary.js";
import FacerecognitioncompanySurat from "./Landing Page/Face Recognition Company/face-recognition-company-Surat.js";
import FacerecognitioncompanyVisakhapatnam from "./Landing Page/Face Recognition Company/face-recognition-company-Visakhapatnam.js";
import FacerecognitioncompanyCoimbatore from "./Landing Page/Face Recognition Company/face-recognition-company-Coimbatore.js";
import FacerecognitioncompanyHosur from "./Landing Page/Face Recognition Company/face-recognition-company-Hosur.js";
import FacerecognitioncompanyPimpareChinchwad from "./Landing Page/Face Recognition Company/face-recognition-company-Pimpare-Chinchwad.js";
import FacerecognitioncompanyShivamoga from "./Landing Page/Face Recognition Company/face-recognition-company-Shivamoga.js";
import FacerecognitioncompanyVijayawada from "./Landing Page/Face Recognition Company/face-recognition-company-Vijayawada.js";
import FacerecognitioncompanyMadurai from "./Landing Page/Face Recognition Company/face-recognition-company-Madurai.js";
import FacerecognitioncompanyTumakuru from "./Landing Page/Face Recognition Company/face-recognition-company-Tumakuru.js";
import FacerecognitiontimeattendancemanagementsystemAbuDhabi from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Abu-Dhabi.js";
import FacerecognitiontimeattendancemanagementsystemArgentina from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system- Argentina.js";
import FacerecognitiontimeattendancemanagementsystemVietnam from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system- Vietnam.js";
import FacerecognitiontimeattendancemanagementsystemAdelaide from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Adelaide.js";
import FacerecognitiontimeattendancemanagementsystemAhmedabad from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Ahmedabad.js";
import FacerecognitiontimeattendancemanagementsystemAlgeria from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Algeria.js";
import FacerecognitiontimeattendancemanagementsystemAmaravati from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Amaravati.js";
import FacerecognitiontimeattendancemanagementsystemAngola from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Angola.js";
import FacerecognitiontimeattendancemanagementsystemAustralia from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Australia.js";
import FacerecognitiontimeattendancemanagementsystemBangladesh from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Bangladesh.js";
import FacerecognitiontimeattendancemanagementsystemBelgaum from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Belgaum.js";
import FacerecognitiontimeattendancemanagementsystemBellary from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Bellary.js";
import FacerecognitiontimeattendancemanagementsystemBengaluru from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Bengaluru.js";
import FacerecognitiontimeattendancemanagementsystemBhopal from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Bhopal.js";
import FacerecognitiontimeattendancemanagementsystemBhuvaneshwar from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Bhuvaneshwar.js";
import FacerecognitiontimeattendancemanagementsystemBirmingham from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Birmingham.js";
import FacerecognitiontimeattendancemanagementsystemBrazil from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Brazil.js";
import FacerecognitiontimeattendancemanagementsystemBrisbane from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Brisbane.js";
import FacerecognitiontimeattendancemanagementsystemBristol from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Bristol.js";
import FacerecognitiontimeattendancemanagementsystemChandigarh from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Chandigarh.js";
import FacerecognitiontimeattendancemanagementsystemCharlottetown from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Charlottetown.js";
import FacerecognitiontimeattendancemanagementsystemChennai from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Chennai.js";
import FacerecognitiontimeattendancemanagementsystemChicago from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Chicago.js";
import FacerecognitiontimeattendancemanagementsystemCoimbatore from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Coimbatore.js";
import FacerecognitiontimeattendancemanagementsystemDelhi from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Delhi.js";
import FacerecognitiontimeattendancemanagementsystemDispur from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Dispur.js";
import FacerecognitiontimeattendancemanagementsystemDubai from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Dubai.js";
import FacerecognitiontimeattendancemanagementsystemEdinburgh from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Edinburgh.js";
import FacerecognitiontimeattendancemanagementsystemEgypt from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Egypt.js";
import FacerecognitiontimeattendancemanagementsystemEthiopia from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Ethiopia.js";
import FacerecognitiontimeattendancemanagementsystemFortWorth from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Fort-Worth.js";
import FacerecognitiontimeattendancemanagementsystemGandhinagar from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Gandhinagar.js";
import FacerecognitiontimeattendancemanagementsystemGhana from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Ghana.js";
import FacerecognitiontimeattendancemanagementsystemGlasgow from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Glasgow.js";
import FacerecognitiontimeattendancemanagementsystemGoa from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Goa.js";
import FacerecognitiontimeattendancemanagementsystemGulbarga from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Gulbarga.js";
import FacerecognitiontimeattendancemanagementsystemHosur from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Hosur.js";
import FacerecognitiontimeattendancemanagementsystemHouston from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Houston.js";
import FacerecognitiontimeattendancemanagementsystemHubliDharwad from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Hubli-Dharwad.js";
import FacerecognitiontimeattendancemanagementsystemHyderabad from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Hyderabad.js";
import FacerecognitiontimeattendancemanagementsystemImphal from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Imphal.js";
import FacerecognitiontimeattendancemanagementsystemIndianapolis from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Indianapolis.js";
import FacerecognitiontimeattendancemanagementsystemIndonesia from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Indonesia.js";
import FacerecognitiontimeattendancemanagementsystemIreland from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Ireland.js";
import FacerecognitiontimeattendancemanagementsystemItanagar from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Itanagar.js";
import FacerecognitiontimeattendancemanagementsystemJaipur from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Jaipur.js";
import FacerecognitiontimeattendancemanagementsystemKenya from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Kenya.js";
import FacerecognitiontimeattendancemanagementsystemKingston from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Kingston .js";
import FacerecognitiontimeattendancemanagementsystemKochi from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Kochi.js";
import FacerecognitiontimeattendancemanagementsystemKolkata from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Kolkata.js";
import FacerecognitiontimeattendancemanagementsystemKuwait from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Kuwait.js";
import FacerecognitiontimeattendancemanagementsystemLasVegas from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Las-Vegas.js";
import FacerecognitiontimeattendancemanagementsystemLiverpool from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Liverpool.js";
import FacerecognitiontimeattendancemanagementsystemLosAngeles from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Los-Angeles.js";
import FacerecognitiontimeattendancemanagementsystemLucknow from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Lucknow.js";
import FacerecognitiontimeattendancemanagementsystemMadurai from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Madurai.js";
import FacerecognitiontimeattendancemanagementsystemMalaysia from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Malaysia.js";
import FacerecognitiontimeattendancemanagementsystemManchester from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Manchester.js";
import FacerecognitiontimeattendancemanagementsystemMangalore from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Mangalore.js";
import FacerecognitiontimeattendancemanagementsystemMelbourne from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Melbourne.js";
import FacerecognitiontimeattendancemanagementsystemMexico from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Mexico.js";
import FacerecognitiontimeattendancemanagementsystemMiami from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Miami.js";
import FacerecognitiontimeattendancemanagementsystemMorocco from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Morocco.js";
import FacerecognitiontimeattendancemanagementsystemMozambique from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Mozambique.js";
import FacerecognitiontimeattendancemanagementsystemMumbai from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Mumbai.js";
import FacerecognitiontimeattendancemanagementsystemMuscat from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Muscat.js";
import FacerecognitiontimeattendancemanagementsystemMysore from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Mysore.js";
import FacerecognitiontimeattendancemanagementsystemNepal from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Nepal.js";
import FacerecognitiontimeattendancemanagementsystemNewYork from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-New-York.js";
import FacerecognitiontimeattendancemanagementsystemNewZealand from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-New-Zealand.js";
import FacerecognitiontimeattendancemanagementsystemNigeria from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Nigeria.js";
import FacerecognitiontimeattendancemanagementsystemNottingham from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Nottingham.js";
import FacerecognitiontimeattendancemanagementsystemOttawa from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Ottawa.js";
import FacerecognitiontimeattendancemanagementsystemPatna from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Patna.js";
import FacerecognitiontimeattendancemanagementsystemPerth from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Perth.js";
import FacerecognitiontimeattendancemanagementsystemPhiladelphia from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Philadelphia.js";
import FacerecognitiontimeattendancemanagementsystemPhilippines from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Philippines.js";
import FacerecognitiontimeattendancemanagementsystemPhoenix from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Phoenix.js";
import FacerecognitiontimeattendancemanagementsystemPimpareChinchwad from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Pimpare-Chinchwad.js";
import FacerecognitiontimeattendancemanagementsystemPune from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Pune.js";
import FacerecognitiontimeattendancemanagementsystemQatar from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Qatar.js";
import FacerecognitiontimeattendancemanagementsystemRanchi from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Ranchi.js";
import FacerecognitiontimeattendancemanagementsystemSanAntonio from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-San-Antonio.js";
import FacerecognitiontimeattendancemanagementsystemSanDiego from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-San-Diego.js";
import FacerecognitiontimeattendancemanagementsystemSaudiArabia from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Saudi-Arabia.js";
import FacerecognitiontimeattendancemanagementsystemSeattle from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Seattle.js";
import FacerecognitiontimeattendancemanagementsystemShimla from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Shimla.js";
import FacerecognitiontimeattendancemanagementsystemShivamoga from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Shivamoga.js";
import FacerecognitiontimeattendancemanagementsystemSingapore from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Singapore.js";
import FacerecognitiontimeattendancemanagementsystemAustin from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Austin.js";
import FacerecognitiontimeattendancemanagementsystemSouthAfrica from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-South-Africa.js";
import FacerecognitiontimeattendancemanagementsystemSriLanka from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-SriLanka.js";
import FacerecognitiontimeattendancemanagementsystemSudan from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Sudan.js";
import FacerecognitiontimeattendancemanagementsystemSurat from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Surat.js";
import FacerecognitiontimeattendancemanagementsystemSydney from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Sydney.js";
import FacerecognitiontimeattendancemanagementsystemThailand from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Thailand.js";
import FacerecognitiontimeattendancemanagementsystemThiruvananthapuram from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Thiruvananthapuram.js";
import FacerecognitiontimeattendancemanagementsystemToronto from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Toronto.js";
import FacerecognitiontimeattendancemanagementsystemTumakuru from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Tumakuru.js";
import FacerecognitiontimeattendancemanagementsystemTurkey from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Turkey.js";
import FacerecognitiontimeattendancemanagementsystemUK from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-UK.js";
import FacerecognitiontimeattendancemanagementsystemVijayawada from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Vijayawada.js";
import FacerecognitiontimeattendancemanagementsystemVisakhapatnam from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Visakhapatnam.js";
import FacerecognitiontimeattendancemanagementsystemWashingtonDC from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Washington-DC.js";
import FacerecognitiontimeattendancemanagementsystemZimbabwe from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Zimbabwe.js";
import FacerecognitiontimeattendancemanagementsystemUganda from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-Uganda.js";
import FacerecognitiontimeattendancemanagementsystemLondon from "./Landing Page/Face recognition attendance/Face-recognition-time-attendance-management-system-London.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionVietnam from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution- Vietnam.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionAbuDhabi from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Abu-Dhabi.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionAmaravati from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Amaravati.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBahrain from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Bahrain.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBangladesh from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Bangladesh.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBelgaum from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Belgaum.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBellary from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Bellary.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBengaluru from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Bengaluru.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBhopal from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Bhopal.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBhuvaneshwar from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Bhuvaneshwar.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionBrazil from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Brazil.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionChandigarh from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Chandigarh.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionChennai from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Chennai.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionCoimbatore from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Coimbatore.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionCyprus from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Cyprus.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionDelhi from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Delhi.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionDispur from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Dispur.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionDubai from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Dubai.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionEgypt from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Egypt.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionEthiopia from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Ethiopia.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionGandhinagar from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Gandhinagar.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionGoa from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Goa.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionGulbarga from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Gulbarga.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionHosur from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Hosur.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionHubliDharwad from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Hubli-Dharwad.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionHyderabad from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Hyderabad.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionImphal from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Imphal.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionIndonesia from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Indonesia.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionThiruvananthapuram from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Thiruvananthapuram.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionIran from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Iran.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionIraq from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Iraq.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionIsrael from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Israel.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionItanagar from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Itanagar.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionJaipur from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Jaipur.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionJordan from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Jordan.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionKenya from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Kenya.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionKochi from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Kochi.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionKolkata from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Kolkata.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionKuwait from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Kuwait.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionLebanon from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Lebanon.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionLucknow from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Lucknow.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionMadurai from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Madurai.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionMalaysia from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Malaysia.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionMangalore from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Mangalore.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionMumbai from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Mumbai.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionMuscat from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Muscat.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionMysore from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Mysore.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionNepal from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Nepal.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionNigeria from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Nigeria.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionOman from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Oman.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionPalestine from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Palestine.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionPatna from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Patna.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionPhilippines from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Philippines.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionPimpareChinchwad from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Pimpare-Chinchwad.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionQatar from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Qatar.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionRanchi from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Ranchi.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionSaudiArabia from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Saudi-Arabia.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionShimla from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Shimla.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionShivamoga from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Shivamoga.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionSingapore from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Singapore.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionSriLanka from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-SriLanka.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionSurat from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Surat.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionSyria from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Syria.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionThailand from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Thailand.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionTumakuru from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Tumakuru.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionTurkey from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Turkey.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionUAE from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-UAE.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionUganda from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Uganda.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionVijayawada from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Vijayawada.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionVisakhapatnam from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Visakhapatnam .js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionYemen from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Yemen.js";
import FaceRecognitionattendanceforschoolbusesVietnam from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses- Vietnam.js";
import FaceRecognitionattendanceforschoolbusesAbuDhabi from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Abu-Dhabi.js";
import FaceRecognitionattendanceforschoolbusesAhmedabad from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Ahmadabad.js";
import FaceRecognitionattendanceforschoolbusesAmaravati from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Amaravati.js";
import FaceRecognitionattendanceforschoolbusesBahrain from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Bahrain.js";
import FaceRecognitionattendanceforschoolbusesBangladesh from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Bangladesh.js";
import FaceRecognitionattendanceforschoolbusesBelgaum from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Belgaum.js";
import FaceRecognitionattendanceforschoolbusesBhopal from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Bhopal.js";
import FaceRecognitionattendanceforschoolbusesBellary from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Bellary.js";
import FaceRecognitionattendanceforschoolbusesBengaluru from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Bengaluru.js";
import FaceRecognitionattendanceforschoolbusesBhuvaneshwar from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Bhuvaneshwar.js";
import FaceRecognitionattendanceforschoolbusesBrazil from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Brazil.js";
import FaceRecognitionattendanceforschoolbusesChandigarh from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Chandigarh.js";
import FaceRecognitionattendanceforschoolbusesChennai from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Chennai.js";
import FaceRecognitionattendanceforschoolbusesCoimbatore from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Coimbatore.js";
import FaceRecognitionattendanceforschoolbusesCyprus from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Cyprus.js";
import FaceRecognitionattendanceforschoolbusesDelhi from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Delhi.js";
import FaceRecognitionattendanceforschoolbusesDispur from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Dispur.js";
import FaceRecognitionattendanceforschoolbusesDubai from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Dubai.js";
import FaceRecognitionattendanceforschoolbusesEgypt from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Egypt.js";
import FaceRecognitionattendanceforschoolbusesEthiopia from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Ethiopia.js";
import FaceRecognitionattendanceforschoolbusesGandhinagar from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Gandhinagar.js";
import FaceRecognitionattendanceforschoolbusesGoa from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Goa.js";
import FaceRecognitionattendanceforschoolbusesGulbarga from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Gulbarga.js";
import FaceRecognitionattendanceforschoolbusesHosur from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Hosur.js";
import FaceRecognitionattendanceforschoolbusesHubliDharwad from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Hubli-Dharwad.js";
import FaceRecognitionattendanceforschoolbusesHyderabad from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Hyderabad.js";
import FaceRecognitionattendanceforschoolbusesImphal from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Imphal.js";
import FaceRecognitionattendanceforschoolbusesIndonesia from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Indonesia.js";
import FaceRecognitionattendanceforschoolbusesIran from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Iran.js";
import FaceRecognitionattendanceforschoolbusesIraq from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Iraq.js";
import FaceRecognitionattendanceforschoolbusesIsrael from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Israel.js";
import FaceRecognitionattendanceforschoolbusesItanagar from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Itanagar.js";
import FaceRecognitionattendanceforschoolbusesJaipur from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Jaipur.js";
import FaceRecognitionattendanceforschoolbusesJordan from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Jordan.js";
import FaceRecognitionattendanceforschoolbusesKenya from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Kenya.js";
import FaceRecognitionattendanceforschoolbusesKochi from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Kochi.js";
import FaceRecognitionattendanceforschoolbusesKolkata from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Kolkata.js";
import FaceRecognitionattendanceforschoolbusesKuwait from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Kuwait.js";
import FaceRecognitionattendanceforschoolbusesLebanon from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Lebanon.js";
import FaceRecognitionattendanceforschoolbusesLucknow from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Lucknow.js";
import FaceRecognitionattendanceforschoolbusesMadurai from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Madurai.js";
import FaceRecognitionattendanceforschoolbusesMalaysia from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Malaysia.js";
import FaceRecognitionattendanceforschoolbusesMangalore from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Mangalore.js";
import FaceRecognitionattendanceforschoolbusesMumbai from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Mumbai.js";
import FaceRecognitionattendanceforschoolbusesMuscat from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Muscat.js";
import FaceRecognitionattendanceforschoolbusesMysore from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Mysore.js";
import FaceRecognitionattendanceforschoolbusesNepal from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Nepal.js";
import FaceRecognitionattendanceforschoolbusesNigeria from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Nigeria.js";
import FaceRecognitionattendanceforschoolbusesOman from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Oman.js";
import FaceRecognitionattendanceforschoolbusesPalestine from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Palestine.js";
import FaceRecognitionattendanceforschoolbusesPatna from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Patna.js";
import FaceRecognitionattendanceforschoolbusesPhilippines from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Philippines.js";
import FaceRecognitionattendanceforschoolbusesPune from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Pune.js";
import FaceRecognitionattendanceforschoolbusesQatar from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Qatar.js";
import FaceRecognitionattendanceforschoolbusesPimpareChinchwad from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Pimpare-Chinchwad.js";
import FaceRecognitionattendanceforschoolbusesRanchi from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Ranchi.js";
import FaceRecognitionattendanceforschoolbusesSaudiArabia from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Saudi-Arabia.js";
import FaceRecognitionattendanceforschoolbusesShimla from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Shimla.js";
import FaceRecognitionattendanceforschoolbusesShivamoga from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Shivamoga.js";
import FaceRecognitionattendanceforschoolbusesSingapore from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Singapore.js";
import FaceRecognitionattendanceforschoolbusesSriLanka from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-SriLanka.js";
import FaceRecognitionattendanceforschoolbusesSurat from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Surat.js";
import FaceRecognitionattendanceforschoolbusesSyria from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Syria.js";
import FaceRecognitionattendanceforschoolbusesThailand from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Thailand.js";
import FaceRecognitionattendanceforschoolbusesThiruvananthapuram from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Thiruvananthapuram.js";
import FaceRecognitionattendanceforschoolbusesTumakuru from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Tumakuru.js";
import FaceRecognitionattendanceforschoolbusesTurkey from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Turkey.js";
import FaceRecognitionattendanceforschoolbusesUAE from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-UAE.js";
import FaceRecognitionattendanceforschoolbusesUganda from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Uganda.js";
import FaceRecognitionattendanceforschoolbusesVijayawada from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Vijayawada.js";
import FaceRecognitionattendanceforschoolbusesVisakhapatnam from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Visakhapatnam .js";
import FaceRecognitionattendanceforschoolbusesYemen from "./Landing Page/Face recognition attendance for schools buses/Face-Recognition-attendance-for-school-buses-Yemen.js";
import FaceRecognitionattendancemanagementsystemeducationalinstitutionAhmedabad from "./Landing Page/Face recognition attendance for schools & colleges/Face-Recognition-attendance-management-system-educational-institution-Ahmedabad.js";
import FacerecognitioncompanyGoa from "./Landing Page/Face Recognition Company/face-recognition-company-Goa.js";
import ITMSautomatedroadtrafficsafetyenforcementAhmedabad from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Ahmedabad.js";
import ITMSautomatedroadtrafficsafetyenforcementAmaravati from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Amaravati.js";
import ITMSautomatedroadtrafficsafetyenforcementBelgaum from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Belgaum.js";
import ITMSautomatedroadtrafficsafetyenforcementBellary from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Bellary.js";
import ITMSautomatedroadtrafficsafetyenforcementBengaluru from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Bengaluru.js";
import ITMSautomatedroadtrafficsafetyenforcementBhopal from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Bhopal.js";
import ITMSautomatedroadtrafficsafetyenforcementBhuvaneshwar from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Bhuvaneshwar.js";
import ITMSautomatedroadtrafficsafetyenforcementChandigarh from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Chandigarh.js";
import ITMSautomatedroadtrafficsafetyenforcementChennai from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Chennai.js";
import ITMSautomatedroadtrafficsafetyenforcementCoimbatore from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Coimbatore.js";
import ITMSautomatedroadtrafficsafetyenforcementDelhi from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Delhi.js";
import ITMSautomatedroadtrafficsafetyenforcementDispur from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Dispur.js";
import ITMSautomatedroadtrafficsafetyenforcementGandhinagar from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Gandhinagar.js";
import ITMSautomatedroadtrafficsafetyenforcementGoa from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Goa.js";
import ITMSautomatedroadtrafficsafetyenforcementGulbarga from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Gulbarga.js";
import ITMSautomatedroadtrafficsafetyenforcementHosur from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Hosur.js";
import ITMSautomatedroadtrafficsafetyenforcementHubliDharwad from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Hubli-Dharwad.js";
import ITMSautomatedroadtrafficsafetyenforcementHyderabad from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Hyderabad.js";
import ITMSautomatedroadtrafficsafetyenforcementImphal from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Imphal.js";
import ITMSautomatedroadtrafficsafetyenforcementItanagar from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Itanagar.js";
import ITMSautomatedroadtrafficsafetyenforcementJaipur from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Jaipur.js";
import ITMSautomatedroadtrafficsafetyenforcementKochi from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Kochi.js";
import ITMSautomatedroadtrafficsafetyenforcementKolkata from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Kolkata.js";
import ITMSautomatedroadtrafficsafetyenforcementLucknow from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Lucknow.js";
import ITMSautomatedroadtrafficsafetyenforcementMadurai from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Madurai.js";
import ITMSautomatedroadtrafficsafetyenforcementMangalore from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Mangalore.js";
import ITMSautomatedroadtrafficsafetyenforcementMumbai from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Mumbai.js";
import ITMSautomatedroadtrafficsafetyenforcementMysore from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Mysore.js";
import ITMSautomatedroadtrafficsafetyenforcementPatna from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Patna.js";
import ITMSautomatedroadtrafficsafetyenforcementPimpareChinchwad from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Pimpare-Chinchwad.js";
import ITMSautomatedroadtrafficsafetyenforcementPune from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Pune.js";
import ITMSautomatedroadtrafficsafetyenforcementRanchi from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Ranchi.js";
import ITMSautomatedroadtrafficsafetyenforcementShimla from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Shimla.js";
import ITMSautomatedroadtrafficsafetyenforcementShivamoga from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Shivamoga.js";
import ITMSautomatedroadtrafficsafetyenforcementSurat from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Surat.js";
import ITMSautomatedroadtrafficsafetyenforcementThiruvananthapuram from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Thiruvananthapuram.js";
import ITMSautomatedroadtrafficsafetyenforcementTumakuru from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Tumakuru.js";
import ITMSautomatedroadtrafficsafetyenforcementVijayawada from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Vijayawada.js";
import ITMSautomatedroadtrafficsafetyenforcementVisakhapatnam from "./Landing Page/ITMS/ITMS-automated-road-traffic-safety-enforcement-Visakhapatnam.js";
import SafetyAhmadabad from "./Landing Page/Safety island/Safety-island-Ahmedabad.js";
import SafetyBengaluru from "./Landing Page/Safety island/Safety-island-Bengaluru.js";
import SafetyBhopal from "./Landing Page/Safety island/Safety-island-Bhopal.js";
import SafetyBhuvaneshwar from "./Landing Page/Safety island/Safety-island-Bhuvaneshwar.js";
import SafetyChandigarh from "./Landing Page/Safety island/Safety-island-Chandigarh.js";
import SafetyChennai from "./Landing Page/Safety island/Safety-island-Chennai.js";
import SafetyDelhi from "./Landing Page/Safety island/Safety-island-Delhi.js";
import SafetyGoa from "./Landing Page/Safety island/Safety-island-Goa.js";
import SafetyHyderabad from "./Landing Page/Safety island/Safety-island-Hyderabad.js";
import SafetyJaipur from "./Landing Page/Safety island/Safety-island-Jaipur.js";
import SafetyKochi from "./Landing Page/Safety island/Safety-island-Kochi.js";
import SafetyKolkata from "./Landing Page/Safety island/Safety-island-Kolkata.js";
import SafetyMangalore from "./Landing Page/Safety island/Safety-island-Mangalore.js";
import SafetyMumbai from "./Landing Page/Safety island/Safety-island-Mumbai.js";
import SafetyPatna from "./Landing Page/Safety island/Safety-island-Patna.js";
import SafetyAmaravati from "./Landing Page/Safety island/Safety-island-Amaravati.js";
import SafetyDispur from "./Landing Page/Safety island/Safety-island-Dispur.js";
import SafetyGandhinagar from "./Landing Page/Safety island/Safety-island-Gandhinagar.js";
import SafetyItanagar from "./Landing Page/Safety island/Safety-island-Itanagar.js";
import SafetyPune from "./Landing Page/Safety island/Safety-island-Pune.js";
import SafetyImphal from "./Landing Page/Safety island/Safety-island-Imphal.js";
import SafetyRanchi from "./Landing Page/Safety island/Safety-island-Ranchi.js";
import SafetyLucknow from "./Landing Page/Safety island/Safety-island-Lucknow.js";
import SafetyShimla from "./Landing Page/Safety island/Safety-island-Shimla.js";
import SafetyThiruvananthapuram from "./Landing Page/Safety island/Safety-island-Thiruvananthapuram.js";
import SafetyBelgaum from "./Landing Page/Safety island/Safety-island-Belgaum.js";
import SafetyBellary from "./Landing Page/Safety island/Safety-island-Bellary.js";
import SafetyGulbarga from "./Landing Page/Safety island/Safety-island-Gulbarga.js";
import SafetyHubliDharwad from "./Landing Page/Safety island/Safety-island-Hubli-Dharwad.js";
import SafetyMysore from "./Landing Page/Safety island/Safety-island-Mysore.js";
import SafetyCoimbatore from "./Landing Page/Safety island/Safety-island-Coimbatore.js";
import SafetyHosur from "./Landing Page/Safety island/Safety-island-Hosur.js";
import SafetyPimpareChinchwad from "./Landing Page/Safety island/Safety-island-Pimpare-Chinchwad.js";
import SafetySurat from "./Landing Page/Safety island/Safety-island-Surat.js";
import SafetyVisakhapatnam from "./Landing Page/Safety island/Safety-island-Visakhapatnam.js";
import SafetyMadurai from "./Landing Page/Safety island/Safety-island-Madurai.js";
import SafetyShivamoga from "./Landing Page/Safety island/Safety-island-Shivamoga.js";
import SafetyTumakuru from "./Landing Page/Safety island/Safety-island-Tumakuru.js";
import SafetyVijayawada from "./Landing Page/Safety island/Safety-island-Vijayawada.js";
import ITMSAhmedabad from "./Landing Page/ITMS-Short/ITMS-Ahmedabad.js";
import ITMSBengaluru from "./Landing Page/ITMS-Short/ITMS-Bengaluru.js";
import ITMSBhopal from "./Landing Page/ITMS-Short/ITMS-Bhopal.js";
import ITMSBhuvaneshwar from "./Landing Page/ITMS-Short/ITMS-Bhuvaneshwar.js";
import ITMSChandigarh from "./Landing Page/ITMS-Short/ITMS-Chandigarh.js";
import ITMSChennai from "./Landing Page/ITMS-Short/ITMS-Chennai.js";
import ITMSDelhi from "./Landing Page/ITMS-Short/ITMS-Delhi.js";
import ITMSGoa from "./Landing Page/ITMS-Short/ITMS-Goa.js";
import ITMSHyderabad from "./Landing Page/ITMS-Short/ITMS-Hyderabad.js";
import ITMSJaipur from "./Landing Page/ITMS-Short/ITMS-Jaipur.js";
import ITMSKochi from "./Landing Page/ITMS-Short/ITMS-Kochi.js";
import ITMSKolkata from "./Landing Page/ITMS-Short/ITMS-Kolkata.js";
import ITMSMangalore from "./Landing Page/ITMS-Short/ITMS-Mangalore.js";
import ITMSMumbai from "./Landing Page/ITMS-Short/ITMS-Mumbai.js";
import ITMSPatna from "./Landing Page/ITMS-Short/ITMS-Patna.js";
import ITMSPune from "./Landing Page/ITMS-Short/ITMS-Pune.js";
import ITMSAmaravati from "./Landing Page/ITMS-Short/ITMS-Amaravati.js";
import ITMSGhandhinagar from "./Landing Page/ITMS-Short/ITMS-Gandhinagar.js";
import ITMSDispur from "./Landing Page/ITMS-Short/ITMS-Dispur.js";
import ITMSItanagar from "./Landing Page/ITMS-Short/ITMS-Itanagar.js";
import ITMSShimla from "./Landing Page/ITMS-Short/ITMS-Shimla.js";
import ITMSImphal from "./Landing Page/ITMS-Short/ITMS-Imphal.js";
import ITMSLucknow from "./Landing Page/ITMS-Short/ITMS-Lucknow.js";
import ITMSMysore from "./Landing Page/ITMS-Short/ITMS-Mysore.js";
import ITMSRanchi from "./Landing Page/ITMS-Short/ITMS-Ranchi.js";
import ITMSThiruvananthapuram from "./Landing Page/ITMS-Short/ITMS-Thiruvananthapuram.js";
import ITMSBelgaum from "./Landing Page/ITMS-Short/ITMS-Belgaum.js";
import ITMSBellary from "./Landing Page/ITMS-Short/ITMS-Bellary.js";
import ITMSGulbarga from "./Landing Page/ITMS-Short/ITMS-Gulbarga.js";
import ITMSHubliDharwad from "./Landing Page/ITMS-Short/ITMS-Hubli-Dharwad.js";
import ITMSSurat from "./Landing Page/ITMS-Short/ITMS-Surat.js";
import ITMSVisakhapatnam from "./Landing Page/ITMS-Short/ITMS-Visakhapatnam.js";
import ITMSCoimbatore from "./Landing Page/ITMS-Short/ITMS-Coimbatore.js";
import ITMSHosur from "./Landing Page/ITMS-Short/ITMS-Hosur.js";
import ITMSPimpareChinchwad from "./Landing Page/ITMS-Short/ITMS-Pimpare-Chinchwad.js";
import ITMSShivamoga from "./Landing Page/ITMS-Short/ITMS-Shivamoga.js";
import ITMSVijayawada from "./Landing Page/ITMS-Short/ITMS-Vijayawada.js";
import ITMSMadurai from "./Landing Page/ITMS-Short/ITMS-Madurai.js";
import ITMSTumakuru from "./Landing Page/ITMS-Short/ITMS-Tumakuru.js";
import VideoAnalyticsAbuDhabi from "./Landing Page/VideoAnalytics/VideoAnalytics-Abu-Dhabi.js";
import VideoAnalyticsAustralia from "./Landing Page/VideoAnalytics/VideoAnalytics-Australia.js";
import VideoAnalyticsBangladesh from "./Landing Page/VideoAnalytics/VideoAnalytics-Bangladesh.js";
import VideoAnalyticsDubai from "./Landing Page/VideoAnalytics/VideoAnalytics-Dubai.js";
import VideoAnalyticsKenya from "./Landing Page/VideoAnalytics/VideoAnalytics-Kenya.js";
import VideoAnalyticsMuscat from "./Landing Page/VideoAnalytics/VideoAnalytics-Muscat.js";
import VideoAnalyticsSingapore from "./Landing Page/VideoAnalytics/VideoAnalytics-Singapore.js";
import VideoAnalyticsZimbabwe from "./Landing Page/VideoAnalytics/VideoAnalytics-Zimbabwe.js";
import VideoAnalyticsEthiopia from "./Landing Page/VideoAnalytics/VideoAnalytics-Ethiopia.js";
import VideoAnalyticsNepal from "./Landing Page/VideoAnalytics/VideoAnalytics-Nepal.js";
import VideoAnalyticsNigeria from "./Landing Page/VideoAnalytics/VideoAnalytics-Nigeria.js";
import VideoAnalyticsSriLanka from "./Landing Page/VideoAnalytics/VideoAnalytics-SriLanka.js";
import VideoAnalyticsUganda from "./Landing Page/VideoAnalytics/VideoAnalytics-Uganda.js";
import VideoAnalyticsEgypt from "./Landing Page/VideoAnalytics/VideoAnalytics-Egypt.js";
import VideoAnalyticsThailand from "./Landing Page/VideoAnalytics/VideoAnalytics-Thailand.js";
import VideoAnalyticsPhilippines from "./Landing Page/VideoAnalytics/VideoAnalytics-Philippines.js";
import VideoAnalyticsIndonesia from "./Landing Page/VideoAnalytics/VideoAnalytics-Indonesia.js";
import VideoAnalyticsUK from "./Landing Page/VideoAnalytics/VideoAnalytics-UK.js";
import VideoAnalyticsIreland from "./Landing Page/VideoAnalytics/VideoAnalytics-Ireland.js";
import VideoAnalyticsMexico from "./Landing Page/VideoAnalytics/VideoAnalytics-Mexico.js";
import VideoAnalyticsBrazil from "./Landing Page/VideoAnalytics/VideoAnalytics-Brazil.js";
import VideoAnalyticsAhmedabad from "./Landing Page/VideoAnalytics/VideoAnalytics-Ahmedabad.js";
import VideoAnalyticsBengaluru from "./Landing Page/VideoAnalytics/VideoAnalytics-Bengaluru.js";
import VideoAnalyticsBhopal from "./Landing Page/VideoAnalytics/VideoAnalytics-Bhopal.js";
import VideoAnalyticsBhuvaneshwar from "./Landing Page/VideoAnalytics/VideoAnalytics-Bhuvaneshwar.js";
import VideoAnalyticsChandigarh from "./Landing Page/VideoAnalytics/VideoAnalytics-Chandigarh.js";
import VideoAnalyticsChennai from "./Landing Page/VideoAnalytics/VideoAnalytics-Chennai.js";
import VideoAnalyticsDelhi from "./Landing Page/VideoAnalytics/VideoAnalytics-Delhi.js";
import VideoAnalyticsGoa from "./Landing Page/VideoAnalytics/VideoAnalytics-Goa.js";
import VideoAnalyticsHyderabad from "./Landing Page/VideoAnalytics/VideoAnalytics-Hyderabad.js";
import VideoAnalyticsJaipur from "./Landing Page/VideoAnalytics/VideoAnalytics-Jaipur.js";
import VideoAnalyticsKochi from "./Landing Page/VideoAnalytics/VideoAnalytics-Kochi.js";
import VideoAnalyticsKolkata from "./Landing Page/VideoAnalytics/VideoAnalytics-Kolkata.js";
import VideoAnalyticsMangalore from "./Landing Page/VideoAnalytics/VideoAnalytics-Mangalore.js";
import VideoAnalyticsMumbai from "./Landing Page/VideoAnalytics/VideoAnalytics-Mumbai.js";
import VideoAnalyticsPatna from "./Landing Page/VideoAnalytics/VideoAnalytics-Patna.js";
import VideoAnalyticsPune from "./Landing Page/VideoAnalytics/VideoAnalytics-Pune.js";
import VideoAnalyticsTurkey from "./Landing Page/VideoAnalytics/VideoAnalytics-Turkey.js";
import VideoAnalyticsKuwait from "./Landing Page/VideoAnalytics/VideoAnalytics-Kuwait.js";
import VideoAnalyticsQatar from "./Landing Page/VideoAnalytics/VideoAnalytics-Qatar.js";
import VideoAnalyticsVietnam from "./Landing Page/VideoAnalytics/VideoAnalytics-Vietnam.js";
import VideoAnalyticsArgentina from "./Landing Page/VideoAnalytics/VideoAnalytics-Argentina.js";
import VideoAnalyticsMalaysia from "./Landing Page/VideoAnalytics/VideoAnalytics-Malaysia.js";
import VideoAnalyticsAlgeria from "./Landing Page/VideoAnalytics/VideoAnalytics-Algeria.js";
import VideoAnalyticsSudan from "./Landing Page/VideoAnalytics/VideoAnalytics-Sudan.js";
import VideoAnalyticsMorocco from "./Landing Page/VideoAnalytics/VideoAnalytics-Morocco.js";
import VideoAnalyticsGhana from "./Landing Page/VideoAnalytics/VideoAnalytics-Ghana.js";
import VideoAnalyticsAngola from "./Landing Page/VideoAnalytics/VideoAnalytics-Angola.js";
import VideoAnalyticsMozambique from "./Landing Page/VideoAnalytics/VideoAnalytics-Mozambique.js";
import VideoAnalyticsMelbourne from "./Landing Page/VideoAnalytics/VideoAnalytics-Melbourne.js";
import VideoAnalyticsSydney from "./Landing Page/VideoAnalytics/VideoAnalytics-Sydney.js";
import VideoAnalyticsBrisbane from "./Landing Page/VideoAnalytics/VideoAnalytics-Brisbane.js";
import VideoAnalyticsPerth from "./Landing Page/VideoAnalytics/VideoAnalytics-Perth.js";
import VideoAnalyticsAdelaide from "./Landing Page/VideoAnalytics/VideoAnalytics-Adelaide.js";
import VideoAnalyticsLondon from "./Landing Page/VideoAnalytics/VideoAnalytics-London.js";
import VideoAnalyticsBirmingham from "./Landing Page/VideoAnalytics/VideoAnalytics-Birmingham.js";
import VideoAnalyticsManchester from "./Landing Page/VideoAnalytics/VideoAnalytics-Manchester.js";
import VideoAnalyticsNottingham from "./Landing Page/VideoAnalytics/VideoAnalytics-Nottingham.js";
import VideoAnalyticsToronto from "./Landing Page/VideoAnalytics/VideoAnalytics-Toronto.js";
import VideoAnalyticsKingston from "./Landing Page/VideoAnalytics/VideoAnalytics-Kingston .js";
import VideoAnalyticsCharlottetown from "./Landing Page/VideoAnalytics/VideoAnalytics-Charlottetown .js";
import VideoAnalyticsOttawa from "./Landing Page/VideoAnalytics/VideoAnalytics-Ottawa .js";
import VideoAnalyticsChicago from "./Landing Page/VideoAnalytics/VideoAnalytics-Chicago.js";
import VideoAnalyticsHouston from "./Landing Page/VideoAnalytics/VideoAnalytics-Houston.js";
import VideoAnalyticsPhoenix from "./Landing Page/VideoAnalytics/VideoAnalytics-Phoenix.js";
import VideoAnalyticsPhiladelphia from "./Landing Page/VideoAnalytics/VideoAnalytics-Philadelphia.js";
import VideoAnalyticsAustin from "./Landing Page/VideoAnalytics/VideoAnalytics-Austin.js";
import VideoAnalyticsSeattle from "./Landing Page/VideoAnalytics/VideoAnalytics-Seattle.js";
import VideoAnalyticsMiami from "./Landing Page/VideoAnalytics/VideoAnalytics-Miami.js";
import VideoAnalyticsIndianapolis from "./Landing Page/VideoAnalytics/VideoAnalytics-Indianapolis.js";
import VideoAnalyticsGlasgow from "./Landing Page/VideoAnalytics/VideoAnalytics-Glasgow.js";
import VideoAnalyticsLiverpool from "./Landing Page/VideoAnalytics/VideoAnalytics-Liverpool.js";
import VideoAnalyticsEdinburgh from "./Landing Page/VideoAnalytics/VideoAnalytics-Edinburgh.js";
import VideoAnalyticsBristol from "./Landing Page/VideoAnalytics/VideoAnalytics-Bristol.js";
import VideoAnalyticsAmaravati from "./Landing Page/VideoAnalytics/VideoAnalytics-Amaravati.js";
import VideoAnalyticsItanagar from "./Landing Page/VideoAnalytics/VideoAnalytics-Itanagar.js";
import VideoAnalyticsDispur from "./Landing Page/VideoAnalytics/VideoAnalytics-Dispur.js";
import VideoAnalyticsGandhinagar from "./Landing Page/VideoAnalytics/VideoAnalytics-Gandhinagar.js";
import VideoAnalyticsShimla from "./Landing Page/VideoAnalytics/VideoAnalytics-Shimla.js";
import VideoAnalyticsRanchi from "./Landing Page/VideoAnalytics/VideoAnalytics-Ranchi.js";
import VideoAnalyticsThiruvananthapuram from "./Landing Page/VideoAnalytics/VideoAnalytics-Thiruvananthapuram.js";
import VideoAnalyticsImphal from "./Landing Page/VideoAnalytics/VideoAnalytics-Imphal.js";
import VideoAnalyticsLucknow from "./Landing Page/VideoAnalytics/VideoAnalytics-Lucknow.js";
import VideoAnalyticsMysore from "./Landing Page/VideoAnalytics/VideoAnalytics-Mysore.js";
import VideoAnalyticsBelgaum from "./Landing Page/VideoAnalytics/VideoAnalytics-Belgaum.js";
import VideoAnalyticsGulbarga from "./Landing Page/VideoAnalytics/VideoAnalytics-Gulbarga.js";
import VideoAnalyticsBellary from "./Landing Page/VideoAnalytics/VideoAnalytics-Bellary.js";
import VideoAnalyticsSurat from "./Landing Page/VideoAnalytics/VideoAnalytics-Surat.js";
import VideoAnalyticsVisakhapatnam from "./Landing Page/VideoAnalytics/VideoAnalytics-Visakhapatnam.js";
import VideoAnalyticsCoimbatore from "./Landing Page/VideoAnalytics/VideoAnalytics-Coimbatore.js";
import VideoAnalyticsHosur from "./Landing Page/VideoAnalytics/VideoAnalytics-Hosur.js";
import VideoAnalyticsShivamoga from "./Landing Page/VideoAnalytics/VideoAnalytics-Shivamoga.js";
import VideoAnalyticsVijayawada from "./Landing Page/VideoAnalytics/VideoAnalytics-Vijayawada.js";
import VideoAnalyticsMadurai from "./Landing Page/VideoAnalytics/VideoAnalytics-Madurai.js";
import VideoAnalyticsTumakuru from "./Landing Page/VideoAnalytics/VideoAnalytics-Tumakuru.js";
import VideoAnalyticsFortWorth from "./Landing Page/VideoAnalytics/VideoAnalytics-Fort-Worth.js";
import VideoAnalyticsHubliDharwad from "./Landing Page/VideoAnalytics/VideoAnalytics-Hubli-Dharwad.js";
import VideoAnalyticsLasVegas from "./Landing Page/VideoAnalytics/VideoAnalytics-Las-Vegas.js";
import VideoAnalyticsLosAngeles from "./Landing Page/VideoAnalytics/VideoAnalytics-Los-Angeles.js";
import VideoAnalyticsNewYork from "./Landing Page/VideoAnalytics/VideoAnalytics-New-York.js";
import VideoAnalyticsNewZealand from "./Landing Page/VideoAnalytics/VideoAnalytics-New-Zealand.js";
import VideoAnalyticsPimpareChinchwad from "./Landing Page/VideoAnalytics/VideoAnalytics-Pimpare-Chinchwad.js";
import VideoAnalyticsSanDiego from "./Landing Page/VideoAnalytics/VideoAnalytics-San-Diego.js";
import VideoAnalyticsSaudiArabia from "./Landing Page/VideoAnalytics/VideoAnalytics-Saudi-Arabia.js";
import VideoAnalyticsSouthAfrica from "./Landing Page/VideoAnalytics/VideoAnalytics-South-Africa.js";
import VideoAnalyticsWashingtonDC from "./Landing Page/VideoAnalytics/VideoAnalytics-Washington-DC.js";
import VideoAnalyticsSanAntonio from "./Landing Page/VideoAnalytics/VideoAnalytics-San-Antonio.js";
import SmartPoleBengaluru from "./Landing Page/Smart Poles/Smart-poles-Bengaluru.js";
import SmartPoleAhmedabad from "./Landing Page/Smart Poles/Smart-poles-Ahmedabad.js";
import SmartPoleBhopal from "./Landing Page/Smart Poles/Smart-poles-Bhopal.js";
import SmartPoleBhuvaneshwar from "./Landing Page/Smart Poles/Smart-poles-Bhuvaneshwar.js";
import SmartPoleChandigarh from "./Landing Page/Smart Poles/Smart-poles-Chandigarh.js";
import SmartPoleChennai from "./Landing Page/Smart Poles/Smart-poles-Chennai.js";
import SmartPoleDelhi from "./Landing Page/Smart Poles/Smart-poles-Delhi.js";
import SmartPoleGoa from "./Landing Page/Smart Poles/Smart-poles-Goa.js";
import SmartPoleHyderabad from "./Landing Page/Smart Poles/Smart-poles-Hyderabad.js";
import SmartPoleJaipur from "./Landing Page/Smart Poles/Smart-poles-Jaipur.js";
import SmartPoleKochi from "./Landing Page/Smart Poles/Smart-poles-Kochi.js";
import SmartPoleKolkata from "./Landing Page/Smart Poles/Smart-poles-Kolkata.js";
import SmartPoleMangalore from "./Landing Page/Smart Poles/Smart-poles-Mangalore.js";
import SmartPoleMumbai from "./Landing Page/Smart Poles/Smart-poles-Mumbai.js";
import SmartPolePatna from "./Landing Page/Smart Poles/Smart-poles-Patna.js";
import SmartPolePune from "./Landing Page/Smart Poles/Smart-poles-Pune.js";
import SmartPoleAmaravati from "./Landing Page/Smart Poles/Smart-poles-Amaravati.js";
import SmartPoleItanagar from "./Landing Page/Smart Poles/Smart-poles-Itanagar.js";
import SmartPoleDispur from "./Landing Page/Smart Poles/Smart-poles-Dispur.js";
import SmartPoleGandhinagar from "./Landing Page/Smart Poles/Smart-poles-Gandhinagar.js";
import SmartPoleShimla from "./Landing Page/Smart Poles/Smart-poles-Shimla.js";
import SmartPoleRanchi from "./Landing Page/Smart Poles/Smart-poles-Ranchi.js";
import SmartPoleThiruvananthapuram from "./Landing Page/Smart Poles/Smart-poles-Thiruvananthapuram.js";
import SmartPoleImphal from "./Landing Page/Smart Poles/Smart-poles-Imphal.js";
import SmartPoleLucknow from "./Landing Page/Smart Poles/Smart-poles-Lucknow.js";
import SmartPoleMysore from "./Landing Page/Smart Poles/Smart-poles-Mysore.js";
import SmartPoleBelgaum from "./Landing Page/Smart Poles/Smart-poles-Belgaum.js";
import SmartPoleGulbarga from "./Landing Page/Smart Poles/Smart-poles-Gulbarga.js";
import SmartPoleBellary from "./Landing Page/Smart Poles/Smart-poles-Bellary.js";
import SmartPoleSurat from "./Landing Page/Smart Poles/Smart-poles-Surat.js";
import SmartPoleVisakhapatnam from "./Landing Page/Smart Poles/Smart-poles-Visakhapatnam.js";
import SmartPoleCoimbatore from "./Landing Page/Smart Poles/Smart-poles-Coimbatore.js";
import SmartPoleHosur from "./Landing Page/Smart Poles/Smart-poles-Hosur.js";
import SmartPoleShivamoga from "./Landing Page/Smart Poles/Smart-poles-Shivamoga.js";
import SmartPoleVijayawada from "./Landing Page/Smart Poles/Smart-poles-Vijayawada.js";
import SmartPoleMadurai from "./Landing Page/Smart Poles/Smart-poles-Madurai.js";
import SmartPoleTumakuru from "./Landing Page/Smart Poles/Smart-poles-Tumakuru.js";
import SmartPoleHubliDharwad from "./Landing Page/Smart Poles/Smart-poles-HubliDharwad.js";
import SmartPolePimpareChinchwad from "./Landing Page/Smart Poles/Smart-poles-PimpareChinchwad.js";

import EzparkingBengaluru from "./Landing Page/Ez Parking/Ezparking-Bengaluru.js";
import EzparkingAhmedabad from "./Landing Page/Ez Parking/Ezparking-Ahmedabad.js";
import EzparkingBhopal from "./Landing Page/Ez Parking/Ezparking-Bhopal.js";
import EzparkingBhuvaneshwar from "./Landing Page/Ez Parking/Ezparking-Bhuvaneshwar.js";
import EzparkingChandigarh from "./Landing Page/Ez Parking/Ezparking-Chandigarh.js";
import EzparkingChennai from "./Landing Page/Ez Parking/Ezparking-Chennai.js";
import EzparkingDelhi from "./Landing Page/Ez Parking/Ezparking-Delhi.js";
import EzparkingGoa from "./Landing Page/Ez Parking/Ezparking-Goa.js";
import EzparkingHyderabad from "./Landing Page/Ez Parking/Ezparking-Hyderabad.js";
import EzparkingJaipur from "./Landing Page/Ez Parking/Ezparking-Jaipur.js";
import EzparkingKochi from "./Landing Page/Ez Parking/Ezparking-Kochi.js";
import EzparkingKolkata from "./Landing Page/Ez Parking/Ezparking-Kolkata.js";
import EzparkingMangalore from "./Landing Page/Ez Parking/Ezparking-Mangalore.js";
import EzparkingMumbai from "./Landing Page/Ez Parking/Ezparking-Mumbai.js";
import EzparkingPatna from "./Landing Page/Ez Parking/Ezparking-Patna.js";
import EzparkingPune from "./Landing Page/Ez Parking/Ezparking-Pune.js";
import EzparkingAmaravati from "./Landing Page/Ez Parking/Ezparking-Amaravati.js";
import EzparkingItanagar from "./Landing Page/Ez Parking/Ezparking-Itanagar.js";
import EzparkingDispur from "./Landing Page/Ez Parking/Ezparking-Dispur.js";
import EzparkingGandhinagar from "./Landing Page/Ez Parking/Ezparking-Gandhinagar.js";
import EzparkingShimla from "./Landing Page/Ez Parking/Ezparking-Shimla.js";
import EzparkingRanchi from "./Landing Page/Ez Parking/Ezparking-Ranchi.js";
import EzparkingThiruvananthapuram from "./Landing Page/Ez Parking/Ezparking-Thiruvananthapuram.js";
import EzparkingImphal from "./Landing Page/Ez Parking/Ezparking-Imphal.js";
import EzparkingLucknow from "./Landing Page/Ez Parking/Ezparking-Lucknow.js";
import EzparkingMysore from "./Landing Page/Ez Parking/Ezparking-Mysore.js";
import EzparkingHubliDharwad from "./Landing Page/Ez Parking/Ezparking-HubliDharwad.js";
import EzparkingBelgaum from "./Landing Page/Ez Parking/Ezparking-Belgaum.js";
import EzparkingGulbarga from "./Landing Page/Ez Parking/Ezparking-Gulbarga.js";
import EzparkingBellary from "./Landing Page/Ez Parking/Ezparking-Bellary.js";
import EzparkingSurat from "./Landing Page/Ez Parking/Ezparking-Surat.js";
import EzparkingVisakhapatnam from "./Landing Page/Ez Parking/Ezparking-Visakhapatnam.js";
import EzparkingCoimbatore from "./Landing Page/Ez Parking/Ezparking-Coimbatore.js";
import EzparkingHosur from "./Landing Page/Ez Parking/Ezparking-Hosur.js";
import EzparkingPimpareChinchwad from "./Landing Page/Ez Parking/Ezparking-PimpareChinchwad.js";
import EzparkingShivamoga from "./Landing Page/Ez Parking/Ezparking-Shivamoga.js";
import EzparkingVijayawada from "./Landing Page/Ez Parking/Ezparking-Vijayawada.js";
import EzparkingMadurai from "./Landing Page/Ez Parking/Ezparking-Madurai.js";
import EzparkingTumakuru from "./Landing Page/Ez Parking/Ezparking-Tumakuru.js";

import ANPRBengaluru from "./Landing Page/Vehicular Access Control/ANPR-Bengaluru.js";
import ANPRAhmedabad from "./Landing Page/Vehicular Access Control/ANPR-Ahmedabad.js";
import ANPRBhopal from "./Landing Page/Vehicular Access Control/ANPR-Bhopal.js";
import ANPRBhuvaneshwar from "./Landing Page/Vehicular Access Control/ANPR-Bhuvaneshwar.js";
import ANPRChandigarh from "./Landing Page/Vehicular Access Control/ANPR-Chandigarh.js";
import ANPRChennai from "./Landing Page/Vehicular Access Control/ANPR-Chennai.js";
import ANPRDelhi from "./Landing Page/Vehicular Access Control/ANPR-Delhi.js";
import ANPRGoa from "./Landing Page/Vehicular Access Control/ANPR-Goa.js";
import ANPRHyderabad from "./Landing Page/Vehicular Access Control/ANPR-Hyderabad.js";
import ANPRJaipur from "./Landing Page/Vehicular Access Control/ANPR-Jaipur.js";
import ANPRKochi from "./Landing Page/Vehicular Access Control/ANPR-Kochi.js";
import ANPRKolkata from "./Landing Page/Vehicular Access Control/ANPR-Kolkata.js";
import ANPRMangalore from "./Landing Page/Vehicular Access Control/ANPR-Mangalore.js";
import ANPRMumbai from "./Landing Page/Vehicular Access Control/ANPR-Mumbai.js";
import ANPRPatna from "./Landing Page/Vehicular Access Control/ANPR-Patna.js";
import ANPRPune from "./Landing Page/Vehicular Access Control/ANPR-Pune.js";
import ANPRAmaravati from "./Landing Page/Vehicular Access Control/ANPR-Amaravati.js";
import ANPRItanagar from "./Landing Page/Vehicular Access Control/ANPR-Itanagar.js";
import ANPRDispur from "./Landing Page/Vehicular Access Control/ANPR-Dispur.js";
import ANPRGandhinagar from "./Landing Page/Vehicular Access Control/ANPR-Gandhinagar.js";
import ANPRShimla from "./Landing Page/Vehicular Access Control/ANPR-Shimla.js";
import ANPRRanchi from "./Landing Page/Vehicular Access Control/ANPR-Ranchi.js";
import ANPRThiruvananthapuram from "./Landing Page/Vehicular Access Control/ANPR-Thiruvananthapuram.js";
import ANPRImphal from "./Landing Page/Vehicular Access Control/ANPR-Imphal.js";
import ANPRLucknow from "./Landing Page/Vehicular Access Control/ANPR-Lucknow.js";
import ANPRMysore from "./Landing Page/Vehicular Access Control/ANPR-Mysore.js";
import ANPRHubliDharwad from "./Landing Page/Vehicular Access Control/ANPR-HubliDharwad.js";
import ANPRBelgaum from "./Landing Page/Vehicular Access Control/ANPR-Belgaum.js";
import ANPRGulbarga from "./Landing Page/Vehicular Access Control/ANPR-Gulbarga.js";
import ANPRBellary from "./Landing Page/Vehicular Access Control/ANPR-Bellary.js";
import ANPRSurat from "./Landing Page/Vehicular Access Control/ANPR-Surat.js";
import ANPRVisakhapatnam from "./Landing Page/Vehicular Access Control/ANPR-Visakhapatnam.js";
import ANPRCoimbatore from "./Landing Page/Vehicular Access Control/ANPR-Coimbatore.js";
import ANPRHosur from "./Landing Page/Vehicular Access Control/ANPR-Hosur.js";
import ANPRPimpareChinchwad from "./Landing Page/Vehicular Access Control/ANPR-PimpareChinchwad.js";
import ANPRShivamoga from "./Landing Page/Vehicular Access Control/ANPR-Shivamoga.js";
import ANPRVijayawada from "./Landing Page/Vehicular Access Control/ANPR-Vijayawada.js";
import ANPRMadurai from "./Landing Page/Vehicular Access Control/ANPR-Madurai.js";
import ANPRTumakuru from "./Landing Page/Vehicular Access Control/ANPR-Tumakuru.js";
import FaceSense from "./FaceSense/FaceSense.js";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="reachus" element={<Reachus />} />
            <Route path="" element={<ProductsLayout />}>
              <Route
                path="face-recognition-technology"
                element={<FaceTech />}
              />
              <Route
                path="automated-traffic-enforcement-system"
                element={<TrafficEnforcement />}
              />
              <Route
                path="perimeter-smart-security-system"
                element={<SeemaSuraksha />}
              />
              <Route
                path="vehicle-telematics"
                element={<VehicleTelematics />}
              />
              <Route path="suraksha-netra" element={<SurakshaNetra />} />
              <Route path="smart-poles" element={<MahilaSuraksha />} />
              <Route
                path="patrol-vehicle-surveillance"
                element={<PatrolSurveillance />}
              />
              <Route
                path="ambulance-signal-free-corridor"
                element={<SignalFreeCorridor />}
              />
              <Route path="smart-pole-solution" element={<SmartPole />} />
            </Route>
            <Route path="Video-Analytics" element={<VideoAnalytics />} />
            <Route path="careers" element={<Career />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<Comingsoon />} />

            {/* <--------------------------------------------Landing page--------------------------------------------------> */}

            {/* <----------------------------------------------Face Recognition Company-----------------------------------------------------------------> */}
            <Route
              path="face-recognition-company-Bengaluru"
              element={<FacerecognitioncompanyBengaluru />}
            />
            <Route
              path="face-recognition-company-Ahmedabad"
              element={<FacerecognitioncompanyAhmedabad />}
            />
            <Route
              path="face-recognition-company-Bhopal"
              element={<FacerecognitioncompanyBhopal />}
            />
            <Route
              path="face-recognition-company-Bhuvaneshwar"
              element={<FacerecognitioncompanyBhuvaneshwar />}
            />
            <Route
              path="face-recognition-company-Chandigarh"
              element={<FacerecognitioncompanyChandigarh />}
            />
            <Route
              path="face-recognition-company-Chennai"
              element={<FacerecognitioncompanyChennai />}
            />
            <Route
              path="face-recognition-company-Delhi"
              element={<FacerecognitioncompanyDelhi />}
            />
            <Route
              path="face-recognition-company-Goa"
              element={<FacerecognitioncompanyGoa />}
            />
            <Route
              path="face-recognition-company-Hyderabad"
              element={<FacerecognitioncompanyHyderabad />}
            />
            <Route
              path="face-recognition-company-Jaipur"
              element={<FacerecognitioncompanyJaipur />}
            />
            <Route
              path="face-recognition-company-Kochi"
              element={<FacerecognitioncompanyKochi />}
            />
            <Route
              path="face-recognition-company-Kolkata"
              element={<FacerecognitioncompanyKolkata />}
            />
            <Route
              path="face-recognition-company-Mangalore"
              element={<FacerecognitioncompanyMangalore />}
            />
            <Route
              path="face-recognition-company-Mumbai"
              element={<FacerecognitioncompanyMumbai />}
            />
            <Route
              path="face-recognition-company-Patna"
              element={<FacerecognitioncompanyPatna />}
            />
            <Route
              path="face-recognition-company-Pune"
              element={<FacerecognitioncompanyPune />}
            />
            <Route
              path="face-recognition-company-Amaravati"
              element={<FacerecognitioncompanyAmaravati />}
            />
            <Route
              path="face-recognition-company-Itanagar"
              element={<FacerecognitioncompanyItanagar />}
            />
            <Route
              path="face-recognition-company-Dispur"
              element={<FacerecognitioncompanyDispur />}
            />
            <Route
              path="face-recognition-company-Gandhinagar"
              element={<FacerecognitioncompanyGandhinagar />}
            />
            <Route
              path="face-recognition-company-Shimla"
              element={<FacerecognitioncompanyShimla />}
            />
            <Route
              path="face-recognition-company-Ranchi"
              element={<FacerecognitioncompanyRanchi />}
            />
            <Route
              path="face-recognition-company-Thiruvananthapuram"
              element={<FacerecognitioncompanyThiruvananthapuram />}
            />
            <Route
              path="face-recognition-company-Imphal"
              element={<FacerecognitioncompanyImphal />}
            />
            <Route
              path="face-recognition-company-Lucknow"
              element={<FacerecognitioncompanyLucknow />}
            />
            <Route
              path="face-recognition-company-Mysore"
              element={<FacerecognitioncompanyMysore />}
            />
            <Route
              path="face-recognition-company-Hubli-Dharwad"
              element={<FacerecognitioncompanyHubliDharwad />}
            />
            <Route
              path="face-recognition-company-Belgaum"
              element={<FacerecognitioncompanyBelgaum />}
            />
            <Route
              path="face-recognition-company-Gulbarga"
              element={<FacerecognitioncompanyGulbarga />}
            />
            <Route
              path="face-recognition-company-Bellary"
              element={<FacerecognitioncompanyBellary />}
            />
            <Route
              path="face-recognition-company-Surat"
              element={<FacerecognitioncompanySurat />}
            />
            <Route
              path="face-recognition-company-Visakhapatnam"
              element={<FacerecognitioncompanyVisakhapatnam />}
            />
            <Route
              path="face-recognition-company-Coimbatore"
              element={<FacerecognitioncompanyCoimbatore />}
            />
            <Route
              path="face-recognition-company-Hosur"
              element={<FacerecognitioncompanyHosur />}
            />
            <Route
              path="face-recognition-company-Pimpare-Chinchwad"
              element={<FacerecognitioncompanyPimpareChinchwad />}
            />
            <Route
              path="face-recognition-company-Shivamoga"
              element={<FacerecognitioncompanyShivamoga />}
            />
            <Route
              path="face-recognition-company-Vijayawada"
              element={<FacerecognitioncompanyVijayawada />}
            />
            <Route
              path="face-recognition-company-Madurai"
              element={<FacerecognitioncompanyMadurai />}
            />
            <Route
              path="face-recognition-company-Tumakuru"
              element={<FacerecognitioncompanyTumakuru />}
            />

            {/* <----------------------------------------------Face recognition attendance--------------------------------------------------------> */}
            <Route
              path="Face-recognition-time-attendance-management-system-Argentina"
              element={
                <FacerecognitiontimeattendancemanagementsystemArgentina />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Vietnam"
              element={<FacerecognitiontimeattendancemanagementsystemVietnam />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Abu-Dhabi"
              element={
                <FacerecognitiontimeattendancemanagementsystemAbuDhabi />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Adelaide"
              element={
                <FacerecognitiontimeattendancemanagementsystemAdelaide />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Ahmedabad"
              element={
                <FacerecognitiontimeattendancemanagementsystemAhmedabad />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Algeria"
              element={<FacerecognitiontimeattendancemanagementsystemAlgeria />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Australia"
              element={
                <FacerecognitiontimeattendancemanagementsystemAustralia />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Amaravati"
              element={
                <FacerecognitiontimeattendancemanagementsystemAmaravati />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Angola"
              element={<FacerecognitiontimeattendancemanagementsystemAngola />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Austin"
              element={<FacerecognitiontimeattendancemanagementsystemAustin />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Bangladesh"
              element={
                <FacerecognitiontimeattendancemanagementsystemBangladesh />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Belgaum"
              element={<FacerecognitiontimeattendancemanagementsystemBelgaum />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Bellary"
              element={<FacerecognitiontimeattendancemanagementsystemBellary />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Bengaluru"
              element={
                <FacerecognitiontimeattendancemanagementsystemBengaluru />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Bhopal"
              element={<FacerecognitiontimeattendancemanagementsystemBhopal />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Bhuvaneshwar"
              element={
                <FacerecognitiontimeattendancemanagementsystemBhuvaneshwar />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Birmingham"
              element={
                <FacerecognitiontimeattendancemanagementsystemBirmingham />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Brazil"
              element={<FacerecognitiontimeattendancemanagementsystemBrazil />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Brisbane"
              element={
                <FacerecognitiontimeattendancemanagementsystemBrisbane />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Bristol"
              element={<FacerecognitiontimeattendancemanagementsystemBristol />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Chandigarh"
              element={
                <FacerecognitiontimeattendancemanagementsystemChandigarh />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Charlottetown"
              element={
                <FacerecognitiontimeattendancemanagementsystemCharlottetown />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Chennai"
              element={<FacerecognitiontimeattendancemanagementsystemChennai />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Chicago"
              element={<FacerecognitiontimeattendancemanagementsystemChicago />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Coimbatore"
              element={
                <FacerecognitiontimeattendancemanagementsystemCoimbatore />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Delhi"
              element={<FacerecognitiontimeattendancemanagementsystemDelhi />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Dispur"
              element={<FacerecognitiontimeattendancemanagementsystemDispur />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Dubai"
              element={<FacerecognitiontimeattendancemanagementsystemDubai />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Edinburgh"
              element={
                <FacerecognitiontimeattendancemanagementsystemEdinburgh />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Egypt"
              element={<FacerecognitiontimeattendancemanagementsystemEgypt />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Ethiopia"
              element={
                <FacerecognitiontimeattendancemanagementsystemEthiopia />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Fort-Worth"
              element={
                <FacerecognitiontimeattendancemanagementsystemFortWorth />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Gandhinagar"
              element={
                <FacerecognitiontimeattendancemanagementsystemGandhinagar />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Ghana"
              element={<FacerecognitiontimeattendancemanagementsystemGhana />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Glasgow"
              element={<FacerecognitiontimeattendancemanagementsystemGlasgow />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Goa"
              element={<FacerecognitiontimeattendancemanagementsystemGoa />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Gulbarga"
              element={
                <FacerecognitiontimeattendancemanagementsystemGulbarga />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Hosur"
              element={<FacerecognitiontimeattendancemanagementsystemHosur />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Houston"
              element={<FacerecognitiontimeattendancemanagementsystemHouston />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Hubli-Dharwad"
              element={
                <FacerecognitiontimeattendancemanagementsystemHubliDharwad />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Hyderabad"
              element={
                <FacerecognitiontimeattendancemanagementsystemHyderabad />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Imphal"
              element={<FacerecognitiontimeattendancemanagementsystemImphal />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Iraq"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionIraq />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Indianapolis"
              element={
                <FacerecognitiontimeattendancemanagementsystemIndianapolis />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Indonesia"
              element={
                <FacerecognitiontimeattendancemanagementsystemIndonesia />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Ireland"
              element={<FacerecognitiontimeattendancemanagementsystemIreland />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Itanagar"
              element={
                <FacerecognitiontimeattendancemanagementsystemItanagar />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Jaipur"
              element={<FacerecognitiontimeattendancemanagementsystemJaipur />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Kenya"
              element={<FacerecognitiontimeattendancemanagementsystemKenya />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Kingston"
              element={
                <FacerecognitiontimeattendancemanagementsystemKingston />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Kochi"
              element={<FacerecognitiontimeattendancemanagementsystemKochi />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Kolkata"
              element={<FacerecognitiontimeattendancemanagementsystemKolkata />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Kuwait"
              element={<FacerecognitiontimeattendancemanagementsystemKuwait />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Las-Vegas"
              element={
                <FacerecognitiontimeattendancemanagementsystemLasVegas />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Liverpool"
              element={
                <FacerecognitiontimeattendancemanagementsystemLiverpool />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Los-Angeles"
              element={
                <FacerecognitiontimeattendancemanagementsystemLosAngeles />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Lucknow"
              element={<FacerecognitiontimeattendancemanagementsystemLucknow />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Madurai"
              element={<FacerecognitiontimeattendancemanagementsystemMadurai />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Malaysia"
              element={
                <FacerecognitiontimeattendancemanagementsystemMalaysia />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Manchester"
              element={
                <FacerecognitiontimeattendancemanagementsystemManchester />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Mangalore"
              element={
                <FacerecognitiontimeattendancemanagementsystemMangalore />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Melbourne"
              element={
                <FacerecognitiontimeattendancemanagementsystemMelbourne />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Mexico"
              element={<FacerecognitiontimeattendancemanagementsystemMexico />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Miami"
              element={<FacerecognitiontimeattendancemanagementsystemMiami />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Morocco"
              element={<FacerecognitiontimeattendancemanagementsystemMorocco />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Mozambique"
              element={
                <FacerecognitiontimeattendancemanagementsystemMozambique />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Mumbai"
              element={<FacerecognitiontimeattendancemanagementsystemMumbai />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Muscat"
              element={<FacerecognitiontimeattendancemanagementsystemMuscat />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Mysore"
              element={<FacerecognitiontimeattendancemanagementsystemMysore />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Nepal"
              element={<FacerecognitiontimeattendancemanagementsystemNepal />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-New-York"
              element={<FacerecognitiontimeattendancemanagementsystemNewYork />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-New-Zealand"
              element={
                <FacerecognitiontimeattendancemanagementsystemNewZealand />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Nigeria"
              element={<FacerecognitiontimeattendancemanagementsystemNigeria />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Nottingham"
              element={
                <FacerecognitiontimeattendancemanagementsystemNottingham />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Ottawa"
              element={<FacerecognitiontimeattendancemanagementsystemOttawa />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Patna"
              element={<FacerecognitiontimeattendancemanagementsystemPatna />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Perth"
              element={<FacerecognitiontimeattendancemanagementsystemPerth />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Philadelphia"
              element={
                <FacerecognitiontimeattendancemanagementsystemPhiladelphia />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Philippines"
              element={
                <FacerecognitiontimeattendancemanagementsystemPhilippines />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Phoenix"
              element={<FacerecognitiontimeattendancemanagementsystemPhoenix />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Pimpare-Chinchwad"
              element={
                <FacerecognitiontimeattendancemanagementsystemPimpareChinchwad />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Pune"
              element={<FacerecognitiontimeattendancemanagementsystemPune />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Qatar"
              element={<FacerecognitiontimeattendancemanagementsystemQatar />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Ranchi"
              element={<FacerecognitiontimeattendancemanagementsystemRanchi />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-San-Antonio"
              element={
                <FacerecognitiontimeattendancemanagementsystemSanAntonio />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-San-Diego"
              element={
                <FacerecognitiontimeattendancemanagementsystemSanDiego />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Saudi-Arabia"
              element={
                <FacerecognitiontimeattendancemanagementsystemSaudiArabia />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Seattle"
              element={<FacerecognitiontimeattendancemanagementsystemSeattle />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Shimla"
              element={<FacerecognitiontimeattendancemanagementsystemShimla />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Shivamoga"
              element={
                <FacerecognitiontimeattendancemanagementsystemShivamoga />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Singapore"
              element={
                <FacerecognitiontimeattendancemanagementsystemSingapore />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-South-Africa"
              element={
                <FacerecognitiontimeattendancemanagementsystemSouthAfrica />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-SriLanka"
              element={
                <FacerecognitiontimeattendancemanagementsystemSriLanka />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Sudan"
              element={<FacerecognitiontimeattendancemanagementsystemSudan />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Surat"
              element={<FacerecognitiontimeattendancemanagementsystemSurat />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Sydney"
              element={<FacerecognitiontimeattendancemanagementsystemSydney />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Thailand"
              element={
                <FacerecognitiontimeattendancemanagementsystemThailand />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Thiruvananthapuram"
              element={
                <FacerecognitiontimeattendancemanagementsystemThiruvananthapuram />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Toronto"
              element={<FacerecognitiontimeattendancemanagementsystemToronto />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Tumakuru"
              element={
                <FacerecognitiontimeattendancemanagementsystemTumakuru />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Turkey"
              element={<FacerecognitiontimeattendancemanagementsystemTurkey />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-UK"
              element={<FacerecognitiontimeattendancemanagementsystemUK />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Vijayawada"
              element={
                <FacerecognitiontimeattendancemanagementsystemVijayawada />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Visakhapatnam"
              element={
                <FacerecognitiontimeattendancemanagementsystemVisakhapatnam />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Washington-DC"
              element={
                <FacerecognitiontimeattendancemanagementsystemWashingtonDC />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Zimbabwe"
              element={
                <FacerecognitiontimeattendancemanagementsystemZimbabwe />
              }
            />
            <Route
              path="Face-recognition-time-attendance-management-system-Uganda"
              element={<FacerecognitiontimeattendancemanagementsystemUganda />}
            />
            <Route
              path="Face-recognition-time-attendance-management-system-London"
              element={<FacerecognitiontimeattendancemanagementsystemLondon />}
            />

            {/* <----------------------------------------------------Face recognition attendance for schools & colleges----------------------------------------------------------> */}

            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Vietnam"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionVietnam />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Abu-Dhabi"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionAbuDhabi />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Ahmedabad"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionAhmedabad />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Amaravati"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionAmaravati />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Bahrain"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBahrain />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Bangladesh"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBangladesh />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Belgaum"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBelgaum />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Bellary"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBellary />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Bengaluru"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBengaluru />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Bhopal"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBhopal />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Bhuvaneshwar"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBhuvaneshwar />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Brazil"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionBrazil />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Chandigarh"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionChandigarh />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Chennai"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionChennai />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Coimbatore"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionCoimbatore />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Cyprus"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionCyprus />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Delhi"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionDelhi />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Dispur"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionDispur />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Dubai"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionDubai />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Egypt"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionEgypt />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Ethiopia"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionEthiopia />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Gandhinagar"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionGandhinagar />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Goa"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionGoa />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Gulbarga"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionGulbarga />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Hosur"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionHosur />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Hubli-Dharwad"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionHubliDharwad />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Hyderabad"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionHyderabad />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Imphal"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionImphal />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Indonesia"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionIndonesia />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Iran"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionIran />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Israel"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionIsrael />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Itanagar"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionItanagar />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Jaipur"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionJaipur />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Jordan"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionJordan />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Kenya"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionKenya />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Kochi"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionKochi />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Kolkata"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionKolkata />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Kuwait"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionKuwait />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Lebanon"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionLebanon />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Lucknow"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionLucknow />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Madurai"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionMadurai />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Malaysia"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionMalaysia />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Mangalore"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionMangalore />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Mumbai"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionMumbai />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Muscat"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionMuscat />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Mysore"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionMysore />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Nepal"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionNepal />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Nigeria"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionNigeria />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Oman"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionOman />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Palestine"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionPalestine />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Patna"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionPatna />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Philippines"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionPhilippines />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Pimpare-Chinchwad"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionPimpareChinchwad />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Pune"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionQatar />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Ranchi"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionRanchi />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Saudi-Arabia"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionSaudiArabia />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Shimla"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionShimla />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Shivamoga"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionShivamoga />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Singapore"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionSingapore />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-SriLanka"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionSriLanka />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Surat"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionSurat />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Syria"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionSyria />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Thailand"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionThailand />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Thiruvananthapuram"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionThiruvananthapuram />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Tumakuru"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionTumakuru />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Turkey"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionTurkey />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-UAE"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionUAE />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Uganda"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionUganda />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Vijayawada"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionVijayawada />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Visakhapatnam"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionVisakhapatnam />
              }
            />
            <Route
              path="Face-Recognition-attendance-management-system-educational-institution-Yemen"
              element={
                <FaceRecognitionattendancemanagementsystemeducationalinstitutionYemen />
              }
            />

            {/* <----------------------------------------------Face Recognition attendance for school buses--------------------------------------------------------------------------------------------> */}

            <Route
              path="Face-Recognition-attendance-for-school-buses-Vietnam"
              element={<FaceRecognitionattendanceforschoolbusesVietnam />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Abu-Dhabi"
              element={<FaceRecognitionattendanceforschoolbusesAbuDhabi />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Ahmedabad"
              element={<FaceRecognitionattendanceforschoolbusesAhmedabad />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Amaravati"
              element={<FaceRecognitionattendanceforschoolbusesAmaravati />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Bahrain"
              element={<FaceRecognitionattendanceforschoolbusesBahrain />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Bangladesh"
              element={<FaceRecognitionattendanceforschoolbusesBangladesh />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Belgaum"
              element={<FaceRecognitionattendanceforschoolbusesBelgaum />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Bellary"
              element={<FaceRecognitionattendanceforschoolbusesBellary />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Bengaluru"
              element={<FaceRecognitionattendanceforschoolbusesBengaluru />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Bhopal"
              element={<FaceRecognitionattendanceforschoolbusesBhopal />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Bhuvaneshwar"
              element={<FaceRecognitionattendanceforschoolbusesBhuvaneshwar />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Brazil"
              element={<FaceRecognitionattendanceforschoolbusesBrazil />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Chandigarh"
              element={<FaceRecognitionattendanceforschoolbusesChandigarh />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Chennai"
              element={<FaceRecognitionattendanceforschoolbusesChennai />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Coimbatore"
              element={<FaceRecognitionattendanceforschoolbusesCoimbatore />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Cyprus"
              element={<FaceRecognitionattendanceforschoolbusesCyprus />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Delhi"
              element={<FaceRecognitionattendanceforschoolbusesDelhi />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Dispur"
              element={<FaceRecognitionattendanceforschoolbusesDispur />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Dubai"
              element={<FaceRecognitionattendanceforschoolbusesDubai />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Egypt"
              element={<FaceRecognitionattendanceforschoolbusesEgypt />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Ethiopia"
              element={<FaceRecognitionattendanceforschoolbusesEthiopia />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Gandhinagar"
              element={<FaceRecognitionattendanceforschoolbusesGandhinagar />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Goa"
              element={<FaceRecognitionattendanceforschoolbusesGoa />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Gulbarga"
              element={<FaceRecognitionattendanceforschoolbusesGulbarga />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Hosur"
              element={<FaceRecognitionattendanceforschoolbusesHosur />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Hubli-Dharwad"
              element={<FaceRecognitionattendanceforschoolbusesHubliDharwad />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Hyderabad"
              element={<FaceRecognitionattendanceforschoolbusesHyderabad />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Imphal"
              element={<FaceRecognitionattendanceforschoolbusesImphal />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Indonesia"
              element={<FaceRecognitionattendanceforschoolbusesIndonesia />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Iran"
              element={<FaceRecognitionattendanceforschoolbusesIran />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Iraq"
              element={<FaceRecognitionattendanceforschoolbusesIraq />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Israel"
              element={<FaceRecognitionattendanceforschoolbusesIsrael />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Itanagar"
              element={<FaceRecognitionattendanceforschoolbusesItanagar />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Jaipur"
              element={<FaceRecognitionattendanceforschoolbusesJaipur />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Jordan"
              element={<FaceRecognitionattendanceforschoolbusesJordan />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Kenya"
              element={<FaceRecognitionattendanceforschoolbusesKenya />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Kochi"
              element={<FaceRecognitionattendanceforschoolbusesKochi />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Kolkata"
              element={<FaceRecognitionattendanceforschoolbusesKolkata />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Kuwait"
              element={<FaceRecognitionattendanceforschoolbusesKuwait />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Lebanon"
              element={<FaceRecognitionattendanceforschoolbusesLebanon />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Lucknow"
              element={<FaceRecognitionattendanceforschoolbusesLucknow />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Madurai"
              element={<FaceRecognitionattendanceforschoolbusesMadurai />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Malaysia"
              element={<FaceRecognitionattendanceforschoolbusesMalaysia />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Mangalore"
              element={<FaceRecognitionattendanceforschoolbusesMangalore />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Mumbai"
              element={<FaceRecognitionattendanceforschoolbusesMumbai />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Muscat"
              element={<FaceRecognitionattendanceforschoolbusesMuscat />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Mysore"
              element={<FaceRecognitionattendanceforschoolbusesMysore />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Nepal"
              element={<FaceRecognitionattendanceforschoolbusesNepal />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Nigeria"
              element={<FaceRecognitionattendanceforschoolbusesNigeria />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Oman"
              element={<FaceRecognitionattendanceforschoolbusesOman />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Palestine"
              element={<FaceRecognitionattendanceforschoolbusesPalestine />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Patna"
              element={<FaceRecognitionattendanceforschoolbusesPatna />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Philippines"
              element={<FaceRecognitionattendanceforschoolbusesPhilippines />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Pimpare-Chinchwad"
              element={
                <FaceRecognitionattendanceforschoolbusesPimpareChinchwad />
              }
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Pune"
              element={<FaceRecognitionattendanceforschoolbusesPune />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Qatar"
              element={<FaceRecognitionattendanceforschoolbusesQatar />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Ranchi"
              element={<FaceRecognitionattendanceforschoolbusesRanchi />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Saudi-Arabia"
              element={<FaceRecognitionattendanceforschoolbusesSaudiArabia />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Shimla"
              element={<FaceRecognitionattendanceforschoolbusesShimla />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Shivamoga"
              element={<FaceRecognitionattendanceforschoolbusesShivamoga />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Singapore"
              element={<FaceRecognitionattendanceforschoolbusesSingapore />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-SriLanka"
              element={<FaceRecognitionattendanceforschoolbusesSriLanka />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Surat"
              element={<FaceRecognitionattendanceforschoolbusesSurat />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Syria"
              element={<FaceRecognitionattendanceforschoolbusesSyria />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Thailand"
              element={<FaceRecognitionattendanceforschoolbusesThailand />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Thiruvananthapuram"
              element={
                <FaceRecognitionattendanceforschoolbusesThiruvananthapuram />
              }
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Tumakuru"
              element={<FaceRecognitionattendanceforschoolbusesTumakuru />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Turkey"
              element={<FaceRecognitionattendanceforschoolbusesTurkey />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-UAE"
              element={<FaceRecognitionattendanceforschoolbusesUAE />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Uganda"
              element={<FaceRecognitionattendanceforschoolbusesUganda />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Vijayawada"
              element={<FaceRecognitionattendanceforschoolbusesVijayawada />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Visakhapatnam"
              element={<FaceRecognitionattendanceforschoolbusesVisakhapatnam />}
            />
            <Route
              path="Face-Recognition-attendance-for-school-buses-Yemen"
              element={<FaceRecognitionattendanceforschoolbusesYemen />}
            />

            {/* <-----------------------------------ITMS---------------------------------------> */}
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Ahmedabad"
              element={<ITMSautomatedroadtrafficsafetyenforcementAhmedabad />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Amaravati"
              element={<ITMSautomatedroadtrafficsafetyenforcementAmaravati />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Belgaum"
              element={<ITMSautomatedroadtrafficsafetyenforcementBelgaum />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Bellary"
              element={<ITMSautomatedroadtrafficsafetyenforcementBellary />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Bengaluru"
              element={<ITMSautomatedroadtrafficsafetyenforcementBengaluru />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Bhopal"
              element={<ITMSautomatedroadtrafficsafetyenforcementBhopal />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Bhuvaneshwar"
              element={
                <ITMSautomatedroadtrafficsafetyenforcementBhuvaneshwar />
              }
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Chandigarh"
              element={<ITMSautomatedroadtrafficsafetyenforcementChandigarh />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Chennai"
              element={<ITMSautomatedroadtrafficsafetyenforcementChennai />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Coimbatore"
              element={<ITMSautomatedroadtrafficsafetyenforcementCoimbatore />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Delhi"
              element={<ITMSautomatedroadtrafficsafetyenforcementDelhi />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Dispur"
              element={<ITMSautomatedroadtrafficsafetyenforcementDispur />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Gandhinagar"
              element={<ITMSautomatedroadtrafficsafetyenforcementGandhinagar />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Goa"
              element={<ITMSautomatedroadtrafficsafetyenforcementGoa />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Gulbarga"
              element={<ITMSautomatedroadtrafficsafetyenforcementGulbarga />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Hosur"
              element={<ITMSautomatedroadtrafficsafetyenforcementHosur />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Hubli-Dharwad"
              element={
                <ITMSautomatedroadtrafficsafetyenforcementHubliDharwad />
              }
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Hyderabad"
              element={<ITMSautomatedroadtrafficsafetyenforcementHyderabad />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Imphal"
              element={<ITMSautomatedroadtrafficsafetyenforcementImphal />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Itanagar"
              element={<ITMSautomatedroadtrafficsafetyenforcementItanagar />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Jaipur"
              element={<ITMSautomatedroadtrafficsafetyenforcementJaipur />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Kochi"
              element={<ITMSautomatedroadtrafficsafetyenforcementKochi />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Kolkata"
              element={<ITMSautomatedroadtrafficsafetyenforcementKolkata />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Lucknow"
              element={<ITMSautomatedroadtrafficsafetyenforcementLucknow />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Madurai"
              element={<ITMSautomatedroadtrafficsafetyenforcementMadurai />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Mangalore"
              element={<ITMSautomatedroadtrafficsafetyenforcementMangalore />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Mumbai"
              element={<ITMSautomatedroadtrafficsafetyenforcementMumbai />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Mysore"
              element={<ITMSautomatedroadtrafficsafetyenforcementMysore />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Patna"
              element={<ITMSautomatedroadtrafficsafetyenforcementPatna />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Pimpare-Chinchwad"
              element={
                <ITMSautomatedroadtrafficsafetyenforcementPimpareChinchwad />
              }
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Pune"
              element={<ITMSautomatedroadtrafficsafetyenforcementPune />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Ranchi"
              element={<ITMSautomatedroadtrafficsafetyenforcementRanchi />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Shimla"
              element={<ITMSautomatedroadtrafficsafetyenforcementShimla />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Shivamoga"
              element={<ITMSautomatedroadtrafficsafetyenforcementShivamoga />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Surat"
              element={<ITMSautomatedroadtrafficsafetyenforcementSurat />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Thiruvananthapuram"
              element={
                <ITMSautomatedroadtrafficsafetyenforcementThiruvananthapuram />
              }
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Tumakuru"
              element={<ITMSautomatedroadtrafficsafetyenforcementTumakuru />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Vijayawada"
              element={<ITMSautomatedroadtrafficsafetyenforcementVijayawada />}
            />
            <Route
              path="ITMS-automated-road-traffic-safety-enforcement-Visakhapatnam"
              element={
                <ITMSautomatedroadtrafficsafetyenforcementVisakhapatnam />
              }
            />

            {/* <-----------------------------------Safety Island---------------------------------------> */}
            <Route
              path="Safety-island-Ahmedabad"
              element={<SafetyAhmadabad />}
            />
            <Route
              path="Safety-island-Bengaluru"
              element={<SafetyBengaluru />}
            />
            <Route path="Safety-island-Bhopal" element={<SafetyBhopal />} />
            <Route
              path="Safety-island-Bhuvaneshwar"
              element={<SafetyBhuvaneshwar />}
            />
            <Route
              path="Safety-island-Chandigarh"
              element={<SafetyChandigarh />}
            />
            <Route path="Safety-island-Chennai" element={<SafetyChennai />} />
            <Route path="Safety-island-Delhi" element={<SafetyDelhi />} />
            <Route path="Safety-island-Goa" element={<SafetyGoa />} />
            <Route
              path="Safety-island-Hyderabad"
              element={<SafetyHyderabad />}
            />
            <Route path="Safety-island-Jaipur" element={<SafetyJaipur />} />
            <Route path="Safety-island-Kochi" element={<SafetyKochi />} />
            <Route path="Safety-island-Kolkata" element={<SafetyKolkata />} />
            <Route
              path="Safety-island-Mangalore"
              element={<SafetyMangalore />}
            />
            <Route path="Safety-island-Mumbai" element={<SafetyMumbai />} />
            <Route path="Safety-island-Patna" element={<SafetyPatna />} />
            <Route
              path="Safety-island-Amaravati"
              element={<SafetyAmaravati />}
            />
            <Route path="Safety-island-Dispur" element={<SafetyDispur />} />
            <Route
              path="Safety-island-Gandhinagar"
              element={<SafetyGandhinagar />}
            />
            <Route path="Safety-island-Itanagar" element={<SafetyItanagar />} />
            <Route path="Safety-island-Pune" element={<SafetyPune />} />
            <Route path="Safety-island-Imphal" element={<SafetyImphal />} />
            <Route path="Safety-island-Ranchi" element={<SafetyRanchi />} />
            <Route path="Safety-island-Lucknow" element={<SafetyLucknow />} />
            <Route path="Safety-island-Shimla" element={<SafetyShimla />} />
            <Route
              path="Safety-island-Thiruvananthapuram"
              element={<SafetyThiruvananthapuram />}
            />
            <Route path="Safety-island-Belgaum" element={<SafetyBelgaum />} />
            <Route path="Safety-island-Bellary" element={<SafetyBellary />} />
            <Route path="Safety-island-Gulbarga" element={<SafetyGulbarga />} />
            <Route
              path="Safety-island-Hubli-Dharwad"
              element={<SafetyHubliDharwad />}
            />
            <Route path="Safety-island-Mysore" element={<SafetyMysore />} />
            <Route
              path="Safety-island-Coimbatore"
              element={<SafetyCoimbatore />}
            />
            <Route path="Safety-island-Hosur" element={<SafetyHosur />} />
            <Route
              path="Safety-island-Pimpare-Chinchwad"
              element={<SafetyPimpareChinchwad />}
            />
            <Route path="Safety-island-Surat" element={<SafetySurat />} />
            <Route
              path="Safety-island-Visakhapatnam"
              element={<SafetyVisakhapatnam />}
            />
            <Route path="Safety-island-Madurai" element={<SafetyMadurai />} />
            <Route
              path="Safety-island-Shivamoga"
              element={<SafetyShivamoga />}
            />
            <Route path="Safety-island-Tumakuru" element={<SafetyTumakuru />} />
            <Route
              path="Safety-island-Vijayawada"
              element={<SafetyVijayawada />}
            />

            {/* <-----------------------------------Video Analytics---------------------------------------> */}
            <Route
              path="Video-Analytics-Abu-Dhabi"
              element={<VideoAnalyticsAbuDhabi />}
            />
            <Route
              path="Video-Analytics-Australia"
              element={<VideoAnalyticsAustralia />}
            />
            <Route
              path="Video-Analytics-Bangladesh"
              element={<VideoAnalyticsBangladesh />}
            />
            <Route
              path="Video-Analytics-Dubai"
              element={<VideoAnalyticsDubai />}
            />
            <Route
              path="Video-Analytics-Kenya"
              element={<VideoAnalyticsKenya />}
            />
            <Route
              path="Video-Analytics-Muscat"
              element={<VideoAnalyticsMuscat />}
            />
            <Route
              path="Video-Analytics-Singapore"
              element={<VideoAnalyticsSingapore />}
            />
            <Route
              path="Video-Analytics-Zimbabwe"
              element={<VideoAnalyticsZimbabwe />}
            />
            <Route
              path="Video-Analytics-Ethiopia"
              element={<VideoAnalyticsEthiopia />}
            />
            <Route
              path="Video-Analytics-Nepal"
              element={<VideoAnalyticsNepal />}
            />
            <Route
              path="Video-Analytics-Nigeria"
              element={<VideoAnalyticsNigeria />}
            />
            <Route
              path="Video-Analytics-SriLanka"
              element={<VideoAnalyticsSriLanka />}
            />
            <Route
              path="Video-Analytics-Uganda"
              element={<VideoAnalyticsUganda />}
            />
            <Route
              path="Video-Analytics-Egypt"
              element={<VideoAnalyticsEgypt />}
            />
            <Route
              path="Video-Analytics-Thailand"
              element={<VideoAnalyticsThailand />}
            />
            <Route
              path="Video-Analytics-Philippines"
              element={<VideoAnalyticsPhilippines />}
            />
            <Route
              path="Video-Analytics-Indonesia"
              element={<VideoAnalyticsIndonesia />}
            />
            <Route path="Video-Analytics-UK" element={<VideoAnalyticsUK />} />
            <Route
              path="Video-Analytics-Ireland"
              element={<VideoAnalyticsIreland />}
            />
            <Route
              path="Video-Analytics-Mexico"
              element={<VideoAnalyticsMexico />}
            />
            <Route
              path="Video-Analytics-Brazil"
              element={<VideoAnalyticsBrazil />}
            />
            <Route
              path="Video-Analytics-Ahmedabad"
              element={<VideoAnalyticsAhmedabad />}
            />
            <Route
              path="Video-Analytics-Bengaluru"
              element={<VideoAnalyticsBengaluru />}
            />
            <Route
              path="Video-Analytics-Bhopal"
              element={<VideoAnalyticsBhopal />}
            />
            <Route
              path="Video-Analytics-Bhuvaneshwar"
              element={<VideoAnalyticsBhuvaneshwar />}
            />
            <Route
              path="Video-Analytics-Chandigarh"
              element={<VideoAnalyticsChandigarh />}
            />
            <Route
              path="Video-Analytics-Chennai"
              element={<VideoAnalyticsChennai />}
            />
            <Route
              path="Video-Analytics-Delhi"
              element={<VideoAnalyticsDelhi />}
            />
            <Route path="Video-Analytics-Goa" element={<VideoAnalyticsGoa />} />
            <Route
              path="Video-Analytics-Hyderabad"
              element={<VideoAnalyticsHyderabad />}
            />
            <Route
              path="Video-Analytics-Jaipur"
              element={<VideoAnalyticsJaipur />}
            />
            <Route
              path="Video-Analytics-Kochi"
              element={<VideoAnalyticsKochi />}
            />
            <Route
              path="Video-Analytics-Kolkata"
              element={<VideoAnalyticsKolkata />}
            />
            <Route
              path="Video-Analytics-Mangalore"
              element={<VideoAnalyticsMangalore />}
            />
            <Route
              path="Video-Analytics-Mumbai"
              element={<VideoAnalyticsMumbai />}
            />
            <Route
              path="Video-Analytics-Patna"
              element={<VideoAnalyticsPatna />}
            />
            <Route
              path="Video-Analytics-Pune"
              element={<VideoAnalyticsPune />}
            />
            <Route
              path="Video-Analytics-Turkey"
              element={<VideoAnalyticsTurkey />}
            />
            <Route
              path="Video-Analytics-Kuwait"
              element={<VideoAnalyticsKuwait />}
            />
            <Route
              path="Video-Analytics-Qatar"
              element={<VideoAnalyticsQatar />}
            />
            <Route
              path="Video-Analytics-Vietnam"
              element={<VideoAnalyticsVietnam />}
            />
            <Route
              path="Video-Analytics-Argentina"
              element={<VideoAnalyticsArgentina />}
            />
            <Route
              path="Video-Analytics-Malaysia"
              element={<VideoAnalyticsMalaysia />}
            />
            <Route
              path="Video-Analytics-Algeria"
              element={<VideoAnalyticsAlgeria />}
            />
            <Route
              path="Video-Analytics-Sudan"
              element={<VideoAnalyticsSudan />}
            />
            <Route
              path="Video-Analytics-Morocco"
              element={<VideoAnalyticsMorocco />}
            />
            <Route
              path="Video-Analytics-Ghana"
              element={<VideoAnalyticsGhana />}
            />
            <Route
              path="Video-Analytics-Angola"
              element={<VideoAnalyticsAngola />}
            />
            <Route
              path="Video-Analytics-Mozambique"
              element={<VideoAnalyticsMozambique />}
            />
            <Route
              path="Video-Analytics-Melbourne"
              element={<VideoAnalyticsMelbourne />}
            />
            <Route
              path="Video-Analytics-Sydney"
              element={<VideoAnalyticsSydney />}
            />
            <Route
              path="Video-Analytics-Brisbane"
              element={<VideoAnalyticsBrisbane />}
            />
            <Route
              path="Video-Analytics-Perth"
              element={<VideoAnalyticsPerth />}
            />
            <Route
              path="Video-Analytics-Adelaide"
              element={<VideoAnalyticsAdelaide />}
            />
            <Route
              path="Video-Analytics-London"
              element={<VideoAnalyticsLondon />}
            />
            <Route
              path="Video-Analytics-Birmingham"
              element={<VideoAnalyticsBirmingham />}
            />
            <Route
              path="Video-Analytics-Manchester"
              element={<VideoAnalyticsManchester />}
            />
            <Route
              path="Video-Analytics-Nottingham"
              element={<VideoAnalyticsNottingham />}
            />
            <Route
              path="Video-Analytics-Toronto"
              element={<VideoAnalyticsToronto />}
            />
            <Route
              path="Video-Analytics-Kingston "
              element={<VideoAnalyticsKingston />}
            />
            <Route
              path="Video-Analytics-Charlottetown "
              element={<VideoAnalyticsCharlottetown />}
            />
            <Route
              path="Video-Analytics-Ottawa "
              element={<VideoAnalyticsOttawa />}
            />
            <Route
              path="Video-Analytics-Chicago"
              element={<VideoAnalyticsChicago />}
            />
            <Route
              path="Video-Analytics-Houston"
              element={<VideoAnalyticsHouston />}
            />
            <Route
              path="Video-Analytics-Phoenix"
              element={<VideoAnalyticsPhoenix />}
            />
            <Route
              path="Video-Analytics-Philadelphia"
              element={<VideoAnalyticsPhiladelphia />}
            />
            <Route
              path="Video-Analytics-Austin"
              element={<VideoAnalyticsAustin />}
            />
            <Route
              path="Video-Analytics-Seattle"
              element={<VideoAnalyticsSeattle />}
            />
            <Route
              path="Video-Analytics-Miami"
              element={<VideoAnalyticsMiami />}
            />
            <Route
              path="Video-Analytics-Indianapolis"
              element={<VideoAnalyticsIndianapolis />}
            />
            <Route
              path="Video-Analytics-Glasgow"
              element={<VideoAnalyticsGlasgow />}
            />
            <Route
              path="Video-Analytics-Liverpool"
              element={<VideoAnalyticsLiverpool />}
            />
            <Route
              path="Video-Analytics-Edinburgh"
              element={<VideoAnalyticsEdinburgh />}
            />
            <Route
              path="Video-Analytics-Bristol"
              element={<VideoAnalyticsBristol />}
            />
            <Route
              path="Video-Analytics-Amaravati"
              element={<VideoAnalyticsAmaravati />}
            />
            <Route
              path="Video-Analytics-Itanagar"
              element={<VideoAnalyticsItanagar />}
            />
            <Route
              path="Video-Analytics-Dispur"
              element={<VideoAnalyticsDispur />}
            />
            <Route
              path="Video-Analytics-Gandhinagar"
              element={<VideoAnalyticsGandhinagar />}
            />
            <Route
              path="Video-Analytics-Shimla"
              element={<VideoAnalyticsShimla />}
            />
            <Route
              path="Video-Analytics-Ranchi"
              element={<VideoAnalyticsRanchi />}
            />
            <Route
              path="Video-Analytics-Thiruvananthapuram"
              element={<VideoAnalyticsThiruvananthapuram />}
            />
            <Route
              path="Video-Analytics-Imphal"
              element={<VideoAnalyticsImphal />}
            />
            <Route
              path="Video-Analytics-Lucknow"
              element={<VideoAnalyticsLucknow />}
            />
            <Route
              path="Video-Analytics-Mysore"
              element={<VideoAnalyticsMysore />}
            />
            <Route
              path="Video-Analytics-Belgaum"
              element={<VideoAnalyticsBelgaum />}
            />
            <Route
              path="Video-Analytics-Gulbarga"
              element={<VideoAnalyticsGulbarga />}
            />
            <Route
              path="Video-Analytics-Bellary"
              element={<VideoAnalyticsBellary />}
            />
            <Route
              path="Video-Analytics-Surat"
              element={<VideoAnalyticsSurat />}
            />
            <Route
              path="Video-Analytics-Visakhapatnam"
              element={<VideoAnalyticsVisakhapatnam />}
            />
            <Route
              path="Video-Analytics-Coimbatore"
              element={<VideoAnalyticsCoimbatore />}
            />
            <Route
              path="Video-Analytics-Hosur"
              element={<VideoAnalyticsHosur />}
            />
            <Route
              path="Video-Analytics-Shivamoga"
              element={<VideoAnalyticsShivamoga />}
            />
            <Route
              path="Video-Analytics-Vijayawada"
              element={<VideoAnalyticsVijayawada />}
            />
            <Route
              path="Video-Analytics-Madurai"
              element={<VideoAnalyticsMadurai />}
            />
            <Route
              path="Video-Analytics-Tumakuru"
              element={<VideoAnalyticsTumakuru />}
            />
            <Route
              path="Video-Analytics-Fort-Worth"
              element={<VideoAnalyticsFortWorth />}
            />
            <Route
              path="Video-Analytics-Hubli-Dharwad"
              element={<VideoAnalyticsHubliDharwad />}
            />
            <Route
              path="Video-Analytics-Las-Vegas"
              element={<VideoAnalyticsLasVegas />}
            />
            <Route
              path="Video-Analytics-Los-Angeles"
              element={<VideoAnalyticsLosAngeles />}
            />
            <Route
              path="Video-Analytics-New-York"
              element={<VideoAnalyticsNewYork />}
            />
            <Route
              path="Video-Analytics-New-Zealand"
              element={<VideoAnalyticsNewZealand />}
            />
            <Route
              path="Video-Analytics-Pimpare-Chinchwad"
              element={<VideoAnalyticsPimpareChinchwad />}
            />
            <Route
              path="Video-Analytics-San-Antonio"
              element={<VideoAnalyticsSanAntonio />}
            />
            <Route
              path="Video-Analytics-San-Diego"
              element={<VideoAnalyticsSanDiego />}
            />
            <Route
              path="Video-Analytics-Saudi-Arabia"
              element={<VideoAnalyticsSaudiArabia />}
            />
            <Route
              path="Video-Analytics-South-Africa"
              element={<VideoAnalyticsSouthAfrica />}
            />
            <Route
              path="Video-Analytics-Washington-DC"
              element={<VideoAnalyticsWashingtonDC />}
            />
            {/* <-----------------------------------ITMS Short---------------------------------------> */}
            <Route path="ITMS-Ahmedabad" element={<ITMSAhmedabad />} />
            <Route path="ITMS-Bengaluru" element={<ITMSBengaluru />} />
            <Route path="ITMS-Bhopal" element={<ITMSBhopal />} />
            <Route path="ITMS-Bhuvaneshwar" element={<ITMSBhuvaneshwar />} />
            <Route path="ITMS-Chandigarh" element={<ITMSChandigarh />} />
            <Route path="ITMS-Chennai" element={<ITMSChennai />} />
            <Route path="ITMS-Delhi" element={<ITMSDelhi />} />
            <Route path="ITMS-Goa" element={<ITMSGoa />} />
            <Route path="ITMS-Hyderabad" element={<ITMSHyderabad />} />
            <Route path="ITMS-Jaipur" element={<ITMSJaipur />} />
            <Route path="ITMS-Kochi" element={<ITMSKochi />} />
            <Route path="ITMS-Kolkata" element={<ITMSKolkata />} />
            <Route path="ITMS-Mangalore" element={<ITMSMangalore />} />
            <Route path="ITMS-Mumbai" element={<ITMSMumbai />} />
            <Route path="ITMS-Patna" element={<ITMSPatna />} />
            <Route path="ITMS-Pune" element={<ITMSPune />} />
            <Route path="ITMS-Amaravati" element={<ITMSAmaravati />} />
            <Route path="ITMS-Dispur" element={<ITMSDispur />} />
            <Route path="ITMS-Ghandhinagar" element={<ITMSGhandhinagar />} />
            <Route path="ITMS-Itanagar" element={<ITMSItanagar />} />
            <Route path="ITMS-Shimla" element={<ITMSShimla />} />
            <Route path="ITMS-Imphal" element={<ITMSImphal />} />
            <Route path="ITMS-Lucknow" element={<ITMSLucknow />} />
            <Route path="ITMS-Mysore" element={<ITMSMysore />} />
            <Route path="ITMS-Ranchi" element={<ITMSRanchi />} />
            <Route
              path="ITMS-Thiruvananthapuram"
              element={<ITMSThiruvananthapuram />}
            />
            <Route path="ITMS-Belgaum" element={<ITMSBelgaum />} />
            <Route path="ITMS-Bellary" element={<ITMSBellary />} />
            <Route path="ITMS-Gulbarga" element={<ITMSGulbarga />} />
            <Route path="ITMS-Hubli-Dharwad" element={<ITMSHubliDharwad />} />
            <Route path="ITMS-Surat" element={<ITMSSurat />} />
            <Route path="ITMS-Visakhapatnam" element={<ITMSVisakhapatnam />} />
            <Route path="ITMS-Coimbatore" element={<ITMSCoimbatore />} />
            <Route path="ITMS-Hosur" element={<ITMSHosur />} />
            <Route
              path="ITMS-Pimpare-Chinchwad"
              element={<ITMSPimpareChinchwad />}
            />
            <Route path="ITMS-Shivamoga" element={<ITMSShivamoga />} />
            <Route path="ITMS-Vijayawada" element={<ITMSVijayawada />} />
            <Route path="ITMS-Madurai" element={<ITMSMadurai />} />
            <Route path="ITMS-Tumakuru" element={<ITMSTumakuru />} />

            {/* <-----------------------------------Smart Pole---------------------------------------> */}
            <Route
              path="Smart-poles-Bengaluru"
              element={<SmartPoleBengaluru />}
            />
            <Route
              path="Smart-poles-Ahmedabad"
              element={<SmartPoleAhmedabad />}
            />
            <Route path="Smart-poles-Bhopal" element={<SmartPoleBhopal />} />
            <Route
              path="Smart-poles-Bhuvaneshwar"
              element={<SmartPoleBhuvaneshwar />}
            />
            <Route
              path="Smart-poles-Chandigarh"
              element={<SmartPoleChandigarh />}
            />
            <Route path="Smart-poles-Chennai" element={<SmartPoleChennai />} />
            <Route path="Smart-poles-Delhi" element={<SmartPoleDelhi />} />
            <Route path="Smart-poles-Goa" element={<SmartPoleGoa />} />
            <Route
              path="Smart-poles-Hyderabad"
              element={<SmartPoleHyderabad />}
            />
            <Route path="Smart-poles-Jaipur" element={<SmartPoleJaipur />} />
            <Route path="Smart-poles-Kochi" element={<SmartPoleKochi />} />
            <Route path="Smart-poles-Kolkata" element={<SmartPoleKolkata />} />
            <Route
              path="Smart-poles-Mangalore"
              element={<SmartPoleMangalore />}
            />
            <Route path="Smart-poles-Mumbai" element={<SmartPoleMumbai />} />
            <Route path="Smart-poles-Patna" element={<SmartPolePatna />} />
            <Route path="Smart-poles-Pune" element={<SmartPolePune />} />
            <Route
              path="Smart-poles-Amaravati"
              element={<SmartPoleAmaravati />}
            />
            <Route
              path="Smart-poles-Itanagar"
              element={<SmartPoleItanagar />}
            />
            <Route path="Smart-poles-Dispur" element={<SmartPoleDispur />} />
            <Route
              path="Smart-poles-Gandhinagar"
              element={<SmartPoleGandhinagar />}
            />
            <Route path="Smart-poles-Shimla" element={<SmartPoleShimla />} />
            <Route path="Smart-poles-Ranchi" element={<SmartPoleRanchi />} />
            <Route
              path="Smart-poles-Thiruvananthapuram"
              element={<SmartPoleThiruvananthapuram />}
            />
            <Route path="Smart-poles-Imphal" element={<SmartPoleImphal />} />
            <Route path="Smart-poles-Lucknow" element={<SmartPoleLucknow />} />
            <Route path="Smart-poles-Mysore" element={<SmartPoleMysore />} />
            <Route path="Smart-poles-Belgaum" element={<SmartPoleBelgaum />} />
            <Route
              path="Smart-poles-Gulbarga"
              element={<SmartPoleGulbarga />}
            />
            <Route path="Smart-poles-Bellary" element={<SmartPoleBellary />} />
            <Route path="Smart-poles-Surat" element={<SmartPoleSurat />} />
            <Route
              path="Smart-poles-Visakhapatnam"
              element={<SmartPoleVisakhapatnam />}
            />
            <Route
              path="Smart-poles-Coimbatore"
              element={<SmartPoleCoimbatore />}
            />
            <Route path="Smart-poles-Hosur" element={<SmartPoleHosur />} />
            <Route
              path="Smart-poles-Shivamoga"
              element={<SmartPoleShivamoga />}
            />
            <Route
              path="Smart-poles-Vijayawada"
              element={<SmartPoleVijayawada />}
            />
            <Route path="Smart-poles-Madurai" element={<SmartPoleMadurai />} />
            <Route
              path="Smart-poles-Tumakuru"
              element={<SmartPoleTumakuru />}
            />
            <Route
              path="Smart-poles-Hubli-Dharwad"
              element={<SmartPoleHubliDharwad />}
            />
            <Route
              path="Smart-poles-Pimpare-Chinchwad"
              element={<SmartPolePimpareChinchwad />}
            />

            {/* <-----------------------------------EzParking---------------------------------------> */}
            <Route
              path="Ezparking-Bengaluru"
              element={<EzparkingBengaluru />}
            />
            <Route
              path="Ezparking-Ahmedabad"
              element={<EzparkingAhmedabad />}
            />
            <Route path="Ezparking-Bhopal" element={<EzparkingBhopal />} />
            <Route
              path="Ezparking-Bhuvaneshwar"
              element={<EzparkingBhuvaneshwar />}
            />
            <Route
              path="Ezparking-Chandigarh"
              element={<EzparkingChandigarh />}
            />
            <Route path="Ezparking-Chennai" element={<EzparkingChennai />} />
            <Route path="Ezparking-Delhi" element={<EzparkingDelhi />} />
            <Route path="Ezparking-Goa" element={<EzparkingGoa />} />
            <Route
              path="Ezparking-Hyderabad"
              element={<EzparkingHyderabad />}
            />
            <Route path="Ezparking-Jaipur" element={<EzparkingJaipur />} />
            <Route path="Ezparking-Kochi" element={<EzparkingKochi />} />
            <Route path="Ezparking-Kolkata" element={<EzparkingKolkata />} />
            <Route
              path="Ezparking-Mangalore"
              element={<EzparkingMangalore />}
            />
            <Route path="Ezparking-Mumbai" element={<EzparkingMumbai />} />
            <Route path="Ezparking-Patna" element={<EzparkingPatna />} />
            <Route path="Ezparking-Pune" element={<EzparkingPune />} />
            <Route
              path="Ezparking-Amaravati"
              element={<EzparkingAmaravati />}
            />
            <Route path="Ezparking-Itanagar" element={<EzparkingItanagar />} />
            <Route path="Ezparking-Dispur" element={<EzparkingDispur />} />
            <Route
              path="Ezparking-Gandhinagar"
              element={<EzparkingGandhinagar />}
            />
            <Route path="Ezparking-Shimla" element={<EzparkingShimla />} />
            <Route path="Ezparking-Ranchi" element={<EzparkingRanchi />} />
            <Route
              path="Ezparking-Thiruvananthapuram"
              element={<EzparkingThiruvananthapuram />}
            />
            <Route path="Ezparking-Imphal" element={<EzparkingImphal />} />
            <Route path="Ezparking-Lucknow" element={<EzparkingLucknow />} />
            <Route path="Ezparking-Mysore" element={<EzparkingMysore />} />
            <Route
              path="Ezparking-Hubli-Dharwad"
              element={<EzparkingHubliDharwad />}
            />
            <Route path="Ezparking-Belgaum" element={<EzparkingBelgaum />} />
            <Route path="Ezparking-Gulbarga" element={<EzparkingGulbarga />} />
            <Route path="Ezparking-Bellary" element={<EzparkingBellary />} />
            <Route path="Ezparking-Surat" element={<EzparkingSurat />} />
            <Route
              path="Ezparking-Visakhapatnam"
              element={<EzparkingVisakhapatnam />}
            />
            <Route
              path="Ezparking-Coimbatore"
              element={<EzparkingCoimbatore />}
            />
            <Route path="Ezparking-Hosur" element={<EzparkingHosur />} />
            <Route
              path="Ezparking-Pimpare-Chinchwad"
              element={<EzparkingPimpareChinchwad />}
            />
            <Route
              path="Ezparking-Shivamoga"
              element={<EzparkingShivamoga />}
            />
            <Route
              path="Ezparking-Vijayawada"
              element={<EzparkingVijayawada />}
            />
            <Route path="Ezparking-Madurai" element={<EzparkingMadurai />} />
            <Route path="Ezparking-Tumakuru" element={<EzparkingTumakuru />} />

            {/* <-----------------------------------ANPR---------------------------------------> */}
            <Route path="ANPR-Bengaluru" element={<ANPRBengaluru />} />
            <Route path="ANPR-Ahmedabad" element={<ANPRAhmedabad />} />
            <Route path="ANPR-Bhopal" element={<ANPRBhopal />} />
            <Route path="ANPR-Bhuvaneshwar" element={<ANPRBhuvaneshwar />} />
            <Route path="ANPR-Chandigarh" element={<ANPRChandigarh />} />
            <Route path="ANPR-Chennai" element={<ANPRChennai />} />
            <Route path="ANPR-Delhi" element={<ANPRDelhi />} />
            <Route path="ANPR-Goa" element={<ANPRGoa />} />
            <Route path="ANPR-Hyderabad" element={<ANPRHyderabad />} />
            <Route path="ANPR-Jaipur" element={<ANPRJaipur />} />
            <Route path="ANPR-Kochi" element={<ANPRKochi />} />
            <Route path="ANPR-Kolkata" element={<ANPRKolkata />} />
            <Route path="ANPR-Mangalore" element={<ANPRMangalore />} />
            <Route path="ANPR-Mumbai" element={<ANPRMumbai />} />
            <Route path="ANPR-Patna" element={<ANPRPatna />} />
            <Route path="ANPR-Pune" element={<ANPRPune />} />
            <Route path="ANPR-Amaravati" element={<ANPRAmaravati />} />
            <Route path="ANPR-Itanagar" element={<ANPRItanagar />} />
            <Route path="ANPR-Dispur" element={<ANPRDispur />} />
            <Route path="ANPR-Gandhinagar" element={<ANPRGandhinagar />} />
            <Route path="ANPR-Shimla" element={<ANPRShimla />} />
            <Route path="ANPR-Ranchi" element={<ANPRRanchi />} />
            <Route
              path="ANPR-Thiruvananthapuram"
              element={<ANPRThiruvananthapuram />}
            />
            <Route path="ANPR-Imphal" element={<ANPRImphal />} />
            <Route path="ANPR-Lucknow" element={<ANPRLucknow />} />
            <Route path="ANPR-Mysore" element={<ANPRMysore />} />
            <Route path="ANPR-Hubli-Dharwad" element={<ANPRHubliDharwad />} />
            <Route path="ANPR-Belgaum" element={<ANPRBelgaum />} />
            <Route path="ANPR-Gulbarga" element={<ANPRGulbarga />} />
            <Route path="ANPR-Bellary" element={<ANPRBellary />} />
            <Route path="ANPR-Surat" element={<ANPRSurat />} />
            <Route path="ANPR-Visakhapatnam" element={<ANPRVisakhapatnam />} />
            <Route path="ANPR-Coimbatore" element={<ANPRCoimbatore />} />
            <Route path="ANPR-Hosur" element={<ANPRHosur />} />
            <Route
              path="ANPR-Pimpare-Chinchwad"
              element={<ANPRPimpareChinchwad />}
            />
            <Route path="ANPR-Shivamoga" element={<ANPRShivamoga />} />
            <Route path="ANPR-Vijayawada" element={<ANPRVijayawada />} />
            <Route path="ANPR-Madurai" element={<ANPRMadurai />} />
            <Route path="ANPR-Tumakuru" element={<ANPRTumakuru />} />
            {/* ----------------FaceSense------------------ */}
            <Route path="FaceSense" element={<FaceSense />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;