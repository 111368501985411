import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import Catalogform from "../../Products/Catalog_Form/Catalogform";
import ReactPlayer from "react-player/lazy";
import { Waypoint } from "react-waypoint";
import Form from "../../Form/Form";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import "./face-recognition-company-Bengaluru.css";
import India from "../../assets/India.svg";

const FaceRecognitionattendanceforschoolbusesHubliDharwad = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  const playerRef = useRef(null);

  const handleExitViewport = () => {
    try {
      if (playerRef.current.getInternalPlayer()) {
        playerRef.current.getInternalPlayer().pauseVideo();
      }
    } catch (error) {
      console.log("");
    }
  };
  const handleScroll = () => {
    const video = document.querySelector(".video_container");
    video.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  return (
    <>
      <Helmet>
        <title>
          School Bus Tracking & Student Attendance System in Hubli-Dharwad |
          Ensuring Safety & Accountability
        </title>
        <meta
          name="description"
          content="Seamlessly integrate our School Bus Tracking and Student Attendance Systems Hubli-Dharwad to ensure the safety and accountability of students. With real-time monitoring of bus routes and accurate attendance tracking, parents and school administrators can ensure timely arrivals, departures, and attendance records. Explore our comprehensive solution for safer and more efficient school transportation today."
        />
        <meta
          name="keyword"
          content="School Bus Tracking in Hubli-Dharwad, Student Attendance System in Hubli-Dharwad, School Bus tracking System in Hubli-Dharwad, School Transportation System in Hubli-Dharwad, Attendance System in Hubli-Dharwad, Student Tracking system in Hubli-Dharwad, Attendance tracking system in Hubli-Dharwad."
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/Face-Recognition-attendance-for-school-buses-Hubli-Dharwad"
        />
      </Helmet>
      <Header />
      <section className="Productpage_Section">
        <div className="product_heading_s">
          <h2>Face Recognition Technology</h2>
        </div>
        <div className="product_details">
          <p>
            <span id="city">Hey Hubli-Dharwad!</span> Face recognition systems
            have been widely used today for various needs ranging from people
            tracking to criminal database management system, street security,
            and defense applications. Our flagship product Facetech is a simple,
            yet powerful face recognition tool for your attendance management
            system. Using Facetech, you can make your existing IP cameras/CCTVs
            into an attendance management system. Yes! That’s right.
          </p>
        </div>
        <Waypoint onLeave={handleExitViewport}>
          <div className="video_container">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=fHY8yOda9KM"
              ref={playerRef}
              controls={true}
              width="100%"
              height="100%"
              onPlay={() => {
                handleScroll();
              }}
            />
          </div>
        </Waypoint>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Facetech is a subscription model product that works on
              pay-as-you-use platform making it simple and easy for you to use
              this as a plug & play product minus the hassles of maintaining it,
              for which we’re always there to support. While you employees walk
              into your lobby, the cameras will capture the footage and mark
              their attendance and notify them on the Facetech app on their iOS
              or Android phones. The HR will access their Facetech dashboards
              for monitoring employee attendance and generate plethora of
              reports for monitoring, tracking, and managing attendance better.
            </p>
          </div>
        </div>
        <div className="application_process">
          <ul>
            <li>
              <p>
                Face recognition model to detect and track people based on 128
                vector points in the face for 100% accuracy for attendance
                management
              </p>
            </li>
            <hr />
            <li>
              <p>
                Integrated Facetech application for managing employees, shifts,
                office timings, and more
              </p>
            </li>
            <hr />
            <li>
              <p>
                Plethora of attendance reports with an integrated dashboard for
                HR
              </p>
            </li>
            <hr />
            <li>
              <p>
                Convert your existing IP cameras into an attendance tool with
                Facetech application
              </p>
            </li>
            <hr />
            <li>
              <p>
                Pay-as-you-use model with subscription license pattern saving
                with big investment
              </p>
            </li>
            <hr />
            <li>
              <p>
                iOS and Android apps for your employees to keep a track of their
                attendance reports
              </p>
            </li>
            <hr />
            <li>
              <p>
                Remote attendance punch for your field employees such as sales,
                installation engineers, business development and more
              </p>
            </li>
            <hr />
            <li>
              <p>
                Integrate multiple offices into one unified platform using
                Facetech, ensuring your HR has access to the attendance of
                employees from multiple offices
              </p>
            </li>
          </ul>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>Govt Offices</li>
              <li>SMB companies</li>
              <li>Industries</li>
              <li>Criminal Database Management System</li>
            </ul>
            <ul>
              <li>Street Security</li>
              <li>Schools & College</li>
              <li>Hostels</li>
              <li>Restaurants & Hotels</li>
              <li>Secretariats</li>
            </ul>
            <ul>
              <li>Visitor Management System</li>
            </ul>
          </div>
        </div>
        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Face recognition technology"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default FaceRecognitionattendanceforschoolbusesHubliDharwad;
