import React, { useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "../../Products/ProductPages/Page.css";
import "../../Products/ProductPages/MahilaSuraksha.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../../Products/Catalog_Form/Catalogform.js";
import image from "../../assets/Suraksha.png";
import icon from "../../assets/Point.svg";
import Header from "../../Header/Header.js";
import Form from "../../Form/Form.js";
import Footer from "../../Footer/Footer.js";
import India from "../../assets/India.svg";

const SafetyImphal = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  return (
    <>
      <Helmet>
        <title>Safety Island Solutions Imphal| Enhancing Road Safety</title>
        <meta
          name="description"
          content="Our Safety Island solutions provide a secure refuge for pedestrians and cyclists, enhancing road safety Imphal. Featuring durable construction and visibility enhancements, these islands promote safer crossings and reduce accidents. Explore our comprehensive safety solutions for urban environments today."
        />
        <meta
          name="keyword"
          content="Safety Island, safety island solutions, Smart Poles Imphal, Best smart pole in Imphal, Smart pole manufacture in Imphal, smart pole manufacture Imphal, smart pole for smart city , Smart Pole Technology , Smart LED street lights in Imphal, Emergency response system in Imphal, safe city in Imphal, smart cities in Imphal."
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/Safety-island-Imphal"
        />
      </Helmet>
      <Header />
      <section className="Productpage_Section">
        <div className="product_heading product_heading_MSSI">
          <h2>Mahila Suraksha Safety Island for Imphal</h2>
        </div>
        <div className="product_details MSSI_product_details">
          <p>
            Women safety is important for a healthy society around us. Ensuring
            safety of women and children while on the streets is the core
            responsibility of the law enforcement. But with vast city areas, it
            becomes a difficult task for police to keep an eye on every possible
            street especially the sensitive areas in the city.
          </p>
        </div>
        <div className="Right_Section">
          <div className="Image_Points pole-1">
            <div className="MPoint1">
              <span className="mpointer_text1">Smart Light with alarm</span>
              <img src={icon} alt="Point" className="pointer_img" />
            </div>
            <div className="MPoint2">
              <span className="mpointer_text2"> Siren </span>
              <img src={icon} alt="Point" />
            </div>
            <div className="MPoint3">
              <img src={icon} alt="Point" />
              <span className="mpointer_text3">AI Edge Cameras</span>
            </div>
            <div className="MPoint4">
              <img src={icon} alt="Point" />
              <span className="mpointer_text4">
                Emergency Response System with 2way audio video connectivity
              </span>
            </div>
            <div className="MPoint5">
              <img src={icon} alt="Point" />
              <span className="mpointer_text5">
                Connectivity with central control room{" "}
              </span>
            </div>
            <div className="MPoint6">
              <img src={icon} alt="Point" />
              <span className="mpointer_text6">
                IP67 Rack with anti-vandal sensors
              </span>
            </div>
            <img src={image} alt="MSSI" />
          </div>
        </div>
        <div className="MSSI_Section">
          <div className="Left_Section">
            <div className="About_product MSSI_About_product">
              <h3>About the Product</h3>
              <div>
                <p>
                  Mahila Suraksha safety islands addresses this problem with a
                  punch. Well-designed product meeting the required
                  functionality, these safety islands are independent and
                  autonomous devices to perform the required task of ensuring
                  safety to women and children while on the streets. Multiple
                  devices, multiple functionalities work together seamlessly to
                  perform one single task i.e., safeguard people in the city and
                  keep the police 24/7 informed about the happenings on the
                  streets.
                </p>
              </div>
            </div>
            <div className="application_process">
              <ul>
                <li>
                  <p>
                    AI cameras focusing all corners of the street giving full
                    view of the street to police
                  </p>
                </li>
                <hr />
                <li>
                  <p>
                    Integrated face recognition for criminal detection on
                    streets
                  </p>
                </li>
                <hr />
                <li>
                  <p>
                    Gesture tracking for instant connectivity of people to the
                    police control room
                  </p>
                </li>
                <hr />
                <li>
                  <p>
                    Emergency response system with panic button integrating this
                    with 2way audio and video to the police control room
                  </p>
                </li>
                <hr />
                <li>
                  <p>Smart light with red alert indicators</p>
                </li>
                <hr />
                <li>
                  <p>Integrated hooter/siren for instant alerts</p>
                </li>
                <hr />
                <li>
                  <p>Robust design with vandal-proof gauge MS structure</p>
                </li>
                <hr />
                <li>
                  <p>
                    Anti-vandal sensors for self-protection of the safety
                    islands
                  </p>
                </li>
              </ul>
            </div>
            <div className="features MSSI_features">
              <h3>Applications</h3>
              <div className="features_containt MSSI_features_containt">
                <ul>
                  <li> City streets</li>
                  <li> Sensitive and troubles areas</li>
                </ul>
                <ul>
                  <li> Ring roads</li>
                  <li> Parks and recreation areas</li>
                </ul>
              </div>
            </div>
            {/* <div>
              <button
                type="button"
                className="download_button MSSI_download_button"
                onClick={() => {
                  setCatalogForm1(true);
                }}
              >
                Dowload catalogue
                <img src={cloudDownload} alt="" />
              </button>
            </div> */}
          </div>
          <div className="Right_Section">
            <div className="Image_Points pole-2">
              <div className="MPoint1">
                <span className="mpointer_text1">Smart Light with alarm</span>
                <img src={icon} alt="Point" className="pointer_img" />
              </div>
              <div className="MPoint2">
                <span className="mpointer_text2"> Siren </span>
                <img src={icon} alt="Point" />
              </div>
              <div className="MPoint3">
                <img src={icon} alt="Point" />
                <span className="mpointer_text3">AI Edge Cameras</span>
              </div>
              <div className="MPoint4">
                <img src={icon} alt="Point" />
                <span className="mpointer_text4">
                  Emergency Response System with 2way audio video connectivity
                </span>
              </div>
              <div className="MPoint5">
                <img src={icon} alt="Point" />
                <span className="mpointer_text5">
                  Connectivity with central control room{" "}
                </span>
              </div>
              <div className="MPoint6">
                <img src={icon} alt="Point" />
                <span className="mpointer_text6">
                  IP67 Rack with anti-vandal sensors
                </span>
              </div>
              <img src={image} alt="MSSI" />
            </div>
          </div>
        </div>
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Data analytics"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default SafetyImphal;
