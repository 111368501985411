import React, { useRef } from "react";
import "../../VideoAnalytics/VideoAnalytics.css";
import { Helmet } from "react-helmet-async";
import DriverAlert from "../../assets/DriverAlert.jpg";
import PeopleTracking from "../../assets/PeopleTracking.jpg";
import Form from "../../Form/Form";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import "../../ComingSoon/comingsoon.css";
import ReactPlayer from "react-player/lazy";
import { Waypoint } from "react-waypoint";
import India from "../../assets/India.svg";

const VideoAnalyticsLondon = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  const playerRefs = useRef([]);
  const handleExitViewport = (index) => () => {
    try {
      if (playerRefs.current[index]) {
        playerRefs.current[index].getInternalPlayer().pauseVideo();
      }
    } catch (error) {
      console.log("");
    }
  };
  const handlPlayPause = (index) => {
    try {
      playerRefs.current.forEach((item, i) => {
        if (index === i) {
          item.getInternalPlayer().playVideo();
        } else {
          item.getInternalPlayer().pauseVideo();
        }
      });
    } catch (error) {
      console.log("");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Edge Video Analytics Technology Software Company London| Empowering
          Surveillance Solutions
        </title>
        <meta
          name="description"
          content="We are a leading provider of video analytics technology solutions London. Our software harnesses the power of AI and machine learning to offer advanced edge video analytics capabilities for security, business intelligence, and operational efficiency. Explore how our innovative software can transform your surveillance infrastructure. "
        />
        <meta
          name="keyword"
          content="Video analytics software London, Video analytics Technology London, Edge Video Analytics Company London, Top video  analytics London, Video analytics software companies London, Video analytics Technology London, Video analytics London, Intelligent video analytics London."
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/Video-Analytics-London"
        />
      </Helmet>
      <Header />
      <section className="videoAnalytics_Section">
        <div className="videoAnalytics_heading">
          <h3>Welcome to our Video Analytics portfolio.</h3>
        </div>
        <div className="videoAnalytics_details">
          <p>
            <strong>Hey London!</strong> We have a wide range of video analytics
            products catering to wide range of sectors and industries. While we
            have off-the-shelf products, we can custom build video analytics to
            suit your exact requirements and problems. These come in various
            deployment options ranging from cloud, on-premise, subscription and
            managed services platform.
          </p>
        </div>
        <div className="videoAnalyticscard_section">
          <div className="videoAnalytics_container">
            <div className="videoAnalytics_card">
              <Waypoint key={1} onLeave={handleExitViewport(1)}>
                <div className="videoAnalytics_video">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=rozrsrYxNDI"
                    id="1"
                    ref={(ref) => (playerRefs.current[1] = ref)}
                    controls={true}
                    onPlay={() => {
                      handlPlayPause(1);
                    }}
                  />
                </div>
              </Waypoint>
              <div className="videoAnalytics_content">
                <h2>Vehicle Classification and Counting</h2>
                <p>
                  Part of our Automated Traffic Enforcement System (ATES),
                  vehicle classification and counting automatically counts
                  vehicles and type of vehicles in the traffic zones giving
                  traffic police an estimate of traffic density on specific
                  roads in the city. This can count thousands of vehicles within
                  its field of view and generate logs, and periodical reports
                  for decision makers.
                </p>
                <p>
                  This can also be integrated with predictive traffic automation
                  models for managing traffic density management to avoid
                  traffic jams better in the city.
                </p>
                <p>
                  This is an essential tool for tracking vehicle density at each
                  traffic junction in the city giving you a cumulative vehicles
                  and type of vehicles plying on the road.
                </p>
              </div>
            </div>
            <div className="videoAnalytics_card">
              <Waypoint key={2} onLeave={handleExitViewport(2)}>
                <div className="videoAnalytics_video">
                  <ReactPlayer
                    id="2"
                    url="https://www.youtube.com/watch?v=_iDFwVUvDp8"
                    ref={(ref) => (playerRefs.current[2] = ref)}
                    controls={true}
                    onPlay={() => {
                      handlPlayPause(2);
                    }}
                  />
                </div>
              </Waypoint>
              <div className="videoAnalytics_content">
                <h2>Vehicle Density Count with Direction</h2>
                <p>
                  Vehicle density integrated with vehicle movement direction is
                  a feature of our ATES stack. This monitors and tracks every
                  vehicle in the junction with its direction of movement. This
                  gives a cumulative count of vehicle movement density in each
                  direction which is a valuable information in efficient traffic
                  management in the city.
                </p>
                <p>
                  This can be integrated with traffic signals to automate
                  vehicle movement for alternate routes to avoid traffic jams in
                  peak hours in the city. This when integrated with our vehicle
                  type classification and count can give you a plethora of
                  information to track vehicle population in each direction in
                  junctions across the city. This can also be used for
                  predictive modelling of traffic management in the city to ease
                  the traffic congestion situations in the city giving the
                  commuters ease of movement.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="videoAnalyticscard_section">
          <div className="videoAnalytics_container">
            <div className="videoAnalytics_card">
              <Waypoint key={3} onLeave={handleExitViewport(3)}>
                <div className="videoAnalytics_video">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=m8MuNAP1YLk"
                    id="3"
                    ref={(ref) => (playerRefs.current[3] = ref)}
                    controls={true}
                    onPlay={() => {
                      handlPlayPause(3);
                    }}
                  />
                </div>
              </Waypoint>
              <div className="videoAnalytics_content">
                <h2>Admetrics</h2>
                <p>
                  Most of the advertisements running on billboards use the
                  tradition measurement techniques to track the visibility of
                  their ads streamed on these billboards. Okulrs have come with
                  an AI driven solution to this to measure your ads visibility
                  to the last dot.
                </p>
                <p>
                  An IP camera with our Admetrics license can do this easily and
                  effectively without a human interference. Admetrics tracks,
                  counts number of people watched your ads on the billboards,
                  gender and their age giving you the demographics and
                  statistics to make the best decision to stream the right ads
                  for the right people, thereby driving revenue and business
                  sense to your customers.
                </p>
                <p>
                  The analytics can give automated periodical reports of your ad
                  visibility with these crucial information and also can
                  integrate with your streaming application for driving related
                  ads according to the people demographics for that specific
                  billboard. Admetrics can also integrate your multiple
                  billboards on our unified platform to monitor and manage all
                  your ads performance from a single location.
                </p>
              </div>
            </div>
            <div className="videoAnalytics_card">
              <Waypoint key={4} onLeave={handleExitViewport(4)}>
                <div className="videoAnalytics_video">
                  <ReactPlayer
                    id="4"
                    url="https://www.youtube.com/watch?v=DZdlYu5KOag"
                    ref={(ref) => (playerRefs.current[4] = ref)}
                    controls={true}
                    onPlay={() => {
                      handlPlayPause(4);
                    }}
                  />
                </div>
              </Waypoint>
              <div className="videoAnalytics_content">
                <h2>Animal - Human Conflict Region Monitoring & Alerts</h2>
                <p>
                  This problem specific to India with core problem occurring
                  from encroachments of forest areas by people, animal and human
                  conflict is on the rise leading to loss of lives of people and
                  endangered animals. With expansive forest area and its extreme
                  climatic conditions, the solution must be robust to handle
                  these extremities and yet perform to save lives and reduce
                  such human & animal conflict zones.
                </p>
                <p>
                  Built on our AI platform, and deployed on tough cameras
                  designed and built for forest and animal tracking, our
                  solution can track animals with the zones and instantly alert
                  the forest authorities on visual and audio alarms while the
                  animal is leaving its forest zone and entering people specific
                  zones.
                </p>
                <p>
                  This is an autonomous system without the need for forest
                  guards to keep vigil at all times, can track animal and human
                  presence within the designated conflict zone with ease. This
                  tool can keep a track, alert and save information captured as
                  evidence for your usage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="videoAnalyticscard_section">
          <div className="videoAnalytics_container">
            <div className="videoAnalytics_card">
              <Waypoint key={5} onLeave={handleExitViewport(5)}>
                <div className="videoAnalytics_video">
                  <ReactPlayer
                    id="5"
                    url="https://www.youtube.com/watch?v=wCTStV2tidQ"
                    ref={(ref) => (playerRefs.current[5] = ref)}
                    controls={true}
                    onPlay={() => {
                      handlPlayPause(5);
                    }}
                  />
                </div>
              </Waypoint>
              <div className="videoAnalytics_content">
                <h2>PPE kit Violation Tracking</h2>
                <p>
                  This problem can be devastating to manufacturing and heavy
                  industries wherein PPE kit violation can lead to loss of lives
                  of employees. Tracking these violations with large teams
                  spread across very large areas across the factories is not a
                  task of a human being.
                </p>
                <p>
                  Our PPE kit violation tracking using your existing network of
                  IP cameras can do this in a jiffy, with every employee in the
                  field of vision of the cameras are specifically tracked for
                  PPE kit as per the protocol or standards defined by the
                  company. Even the smallest violation in this, will be tracked,
                  captured and notified to the authorities with snippet evidence
                  for further actioning thereby saving lives of employees.
                </p>
              </div>
            </div>
            <div className="videoAnalytics_card">
              <Waypoint key={6} onLeave={handleExitViewport(6)}>
                <div className="videoAnalytics_video">
                  <ReactPlayer
                    id="6"
                    url="https://www.youtube.com/watch?v=uQYCzwFBcRk"
                    ref={(ref) => (playerRefs.current[6] = ref)}
                    controls={true}
                    onPlay={() => {
                      handlPlayPause(6);
                    }}
                  />
                </div>
              </Waypoint>
              <div className="videoAnalytics_content">
                <h2>Smoke & Fire detection</h2>
                <p>
                  Your existing IP cameras can become an effective tool for
                  instant smoke and fire detection at your warehouse, office,
                  malls, hotels & restaurants, events and more.
                </p>
                <p>
                  Our video analytics tool is trained and designed to quickly
                  track smoke and fire among its designated areas within its
                  field of view. With an integrated IoT device, this analytics
                  can also trigger your fire safety equipments, saving lives and
                  property at the nick of time.
                </p>
                <p>
                  This is equipped with loud audio alerts to facilitate
                  clearance of the area. The integrated evidence logs will have
                  the video and image snippets for further usage and archival.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="videoAnalyticscard_section">
          <div className="videoAnalytics_container">
            <div className="videoAnalytics_card">
              <Waypoint key={7} onLeave={handleExitViewport(7)}>
                <div className="videoAnalytics_video">
                  <ReactPlayer
                    id="7"
                    url="https://www.youtube.com/watch?v=-2M1QFsXFl0"
                    ref={(ref) => (playerRefs.current[7] = ref)}
                    controls={true}
                    onPlay={() => {
                      handlPlayPause(7);
                    }}
                  />
                </div>
              </Waypoint>
              <div className="videoAnalytics_content">
                <h2>Crowd Detection and Monitoring</h2>
                <p>
                  Whether its city streets, election campaigns or mega events,
                  monitoring and tracking every aspect of this crowd becomes
                  very important to ensure nothing unpleasant or incidents
                  happen.
                </p>
                <p>
                  Autonomous systems driven on AI helps you handle this
                  gargantuan problem by its neck. Our video analytics platform
                  is designed and developed to handle large crowd or mob
                  monitoring, movement direction, count of people, heatmap of
                  density and movement. Instant audio and video alerts helps law
                  enforcement to thwart incidents before it happens. Log files
                  created are stored for archival and further investigations at
                  later stage. This also can be clubbed with our Facetech to
                  connect with criminal tracking within the designated areas
                  within the crowd or mob.
                </p>
                <p>
                  Crowd density mapping&nbsp;| People counting in crowd&nbsp;|
                  Crowd movement direction tracking&nbsp;| Heatmap with density
                  analysis&nbsp;| Audio alerts locally and ICCC&nbsp;| Video
                  alerts locally and ICCC&nbsp;| Automated video and snippets
                  for evidence management
                </p>
              </div>
            </div>
            <div className="videoAnalytics_card">
              <div className="videoAnalytics_img">
                <img src={PeopleTracking} alt="" />
              </div>
              <div className="videoAnalytics_content">
                <h2>People Tracking</h2>
                <p>
                  If you have a suspect and you need to track the person in your
                  designated areas, then we have this solution that’s a fitting
                  match to solve this problem. Using your existing IP cameras
                  covering the entirety of your designated area, our people
                  tracking product can pinpoint the exact location of the
                  person.
                </p>
                <p>
                  Use our pin to point to the specific person you wish to track
                  within the designated area, that’s it. This pin is tracked by
                  every camera in the area for this person with pinpoint
                  location tracked, automatically photographed and alert the
                  concerned people about this pointer. This also gives you the
                  complete path the person has travelled within the area.
                </p>
                <p>
                  To add to this, this can be implemented to track the same
                  person at multiple places and geographies across the world.
                  Working on a unified platform and compatible with any make of
                  IP cameras, this can be the absolute tool to track your most
                  wanted person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="videoAnalyticscard_section">
          <div className="videoAnalyticscard_section">
            <div className="videoAnalytics_container">
              <div className="videoAnalytics_card">
                <Waypoint key={8} onLeave={handleExitViewport(8)}>
                  <div className="videoAnalytics_video">
                    <ReactPlayer
                      id="8"
                      url="https://www.youtube.com/watch?v=TrcvZCuS23w"
                      ref={(ref) => (playerRefs.current[8] = ref)}
                      controls={true}
                      onPlay={() => {
                        handlPlayPause(8);
                      }}
                    />
                  </div>
                </Waypoint>
                <div className="videoAnalytics_content">
                  <h2>People Counting</h2>
                  <p>
                    Retail stores, malls, events, high security enclosures or
                    any other entity that wants to track count of people coming
                    in and going out, this is the perfect solution you could
                    possibly find.
                  </p>
                  <p>
                    People counting along with demographics such as gender, age
                    range of people walking through the entrance and exits are
                    mapped, tracked and monitored. Automated reports helps the
                    security and decision makers with the details of foot falls,
                    exits for the day and to ensure that every person entered
                    has exit. This is an important tool for high security
                    premises wherein every person entering and exit must be
                    counted and logged to ensure tight security is maintained.
                    This combined with visitor management system is a great
                    combo to manage large volume visitors such as secretariates,
                    VIP buildings, defence establishments, prisons, and
                    important Govt buildings.
                  </p>
                </div>
              </div>
              <div className="videoAnalytics_card">
                <div className="videoAnalytics_img">
                  <img src={DriverAlert} alt="" />
                </div>
                <div className="videoAnalytics_content">
                  <h2>Driver Alert System</h2>
                  <p>
                    Part of our vehicle telematics platform, Driver Alert System
                    or DAS as we fondly call is a life saving device that can
                    protect passengers lives from a reckless and irresponsible
                    driver. Specific to passenger transport buses, trucks, oil
                    tankers, long distance cabs and vehicles, DAS constantly
                    monitors the driver for drowsiness, smoking while driving,
                    attention distraction and mobile phone usage while driving.
                  </p>
                  <p>
                    Being an autonomous system, DAS will alert the driver with
                    its in built audio alerts to caution the driver and bring
                    back the focus to attentive driving. This also creates
                    automated log file with video and image snippets in the form
                    of a report to help the decision makers know about the
                    driving behaviour pattern of the drivers enabling them to
                    take affirmative decision for safe journey of their
                    passenger always.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default VideoAnalyticsLondon;
