import React from "react";
import "./Reachus.css";
import { Helmet } from "react-helmet-async";
import Header from "../Header/Header";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import icon1 from "../assets/Phone.svg";
import icon2 from "../assets/Mail.svg";
import icon3 from "../assets/Location.svg";
import icon4 from "../assets/CheckIcon.svg";

const Reachus = () => {
  return (
    <div>
      <Helmet>
        <title>Reach Us | Get in Touch for AI solutions and products</title>
        <meta
          name="description"
          content="Contact us to explore our solutions or receive support. Our team is here to assist you. Reach out today!"
        />
        <meta name="keyword" content="Contact us, Okulr" />
        <link rel="canonical" href="https://www.okulr.com/reachus" />
      </Helmet>
      <Header />
      <div className="Reachus_components">
        <div className="Reachus_Box">
          <div className="LReach">
            <iframe
              title="Okulr Address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d612.2335158029152!2d77.5545589412242!3d13.007582648639508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa8cc69670d7df1bd%3A0xc86a75010ee0324c!2sOkulr%20Techminds!5e0!3m2!1sen!2sin!4v1711430607413!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              frameBorder="0"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="Map_overlay"></div>
          </div>
          <div className="RReach">
            <div className="RReach_components">
              <div className="RContact">
                <div className="RContact_img">
                  <img src={icon3} alt="Location" />
                </div>
                <div className="RContact_text">
                  <h2>Get In</h2>
                  <a
                    href="https://maps.app.goo.gl/wsRuSUohJ45Qk5rU6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Okulr Techminds</strong>
                    <br />
                    Registered Office: Soudhamini, 122, B-Block, III main,
                    Gruhalakshmi Layout II Stage, Kamalanagar,
                    Basaveshwaranagar, Bengaluru 560079 Karnataka India
                  </a>
                </div>
              </div>
              <div
                className="RContact"
                style={{
                  borderTop: "1px solid #dedede",
                  borderBottom: "1px solid #dedede",
                }}
              >
                <div className="RContact_img">
                  <img src={icon1} alt="Phone" />
                </div>
                <div className="RContact_text">
                  <h2>Call Us</h2>
                  <div className="reachus_contactNo">
                    <a href="tel:93640 16100">+91&nbsp;93640&nbsp;16100</a>
                    <span>&emsp;|&emsp;</span>
                    <a href="tel:80 2322 4300">
                      +91&nbsp;80&nbsp;2322&nbsp;4300
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="RContact"
                style={{
                  borderTop: "1px solid #dedede",
                  borderBottom: "1px solid #dedede",
                }}
              >
                <div className="RContact_img">
                  <img src={icon2} alt="Mail" />
                </div>
                <div className="RContact_text">
                  <h2>E-Mail Us</h2>
                  <a href="mailto:contact@okulr.com">contact@okulr.com</a>
                </div>
              </div>
              <div className="RContact">
                <div className="RContact_img">
                  <img src={icon4} alt="Clock" />
                </div>
                <div className="RContact_text">
                  <div className="reachus_contactNo">
                    <p>
                      Business Timings
                      (Mon&nbsp;-&nbsp;Fri&nbsp;.&nbsp;10AM&nbsp;-&nbsp;5PM)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form />
      <Footer />
    </div>
  );
};

export default Reachus;
