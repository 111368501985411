import React, { useState } from "react";
//import cloudDownload from "../../assets/cloudDownload.svg";
import "./Ezparking.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../../Products/Catalog_Form/Catalogform";
import India from "../../assets/India.svg";
import ezparking_banner from "../../assets/ezparking_banner.svg";
import Footer from "../../Footer/Footer";
import Form from "../../Form/Form";
import Header from "../../Header/Header";
import Ezparking from "../../assets/Ezparking.svg";

const EzparkingMysore = () => {
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  const [catalogForm1, setCatalogForm1] = useState(false);
  return (
    <>
      <Helmet>
        <title>
          Smart Parking Management Technology System Mysore | Ezparking
        </title>
        <meta
          name="description"
          content="Our Parking Management System optimizes parking operations with advanced technology Mysore. From space availability monitoring to automated payment systems, our solution enhances efficiency, reduces congestion, and improves user experience. Explore our comprehensive paperless parking solutions for smarter cities today."
        />
        <meta
          name="keyword"
          content="Automated parking system Mysore, Parking system in Mysore, Automated parking in Mysore, Multi-level parking in Mysore, Paperless parking management in Mysore, Automated parking companies in Mysore, Parking management service in Mysore, parking management Mysore, car parking management Mysore, parking space management Mysore, parking service management Mysore, parking control management Mysore."
        />
        <link rel="canonical" href="https://www.okulr.com/Ezparking-Mysore" />
      </Helmet>
      <Header />
      <section className="Productpage_Section">
        <div className="product_heading_ez">
          {/* <h2>EZ Parking for Mysore</h2> */}
          <img src={Ezparking} alt="logo" />
        </div>
        <div className="product_details">
          <h1>Smart, Paperless & Easy Parking solution</h1>
          <p>
            <strong> Hey Mysore!&nbsp;</strong>EZParking from Okulr uses
            artificial intelligence to automate your parking space with ease. As
            the name suggests, parking must be easy and not complicated. When
            banking, bill payments, education, transport, shopping and many more
            sectors have gone paperless, why should parking management be on the
            age-old technology of paper-based parking ticket?{" "}
          </p>
        </div>
        <div className="video_container">
          <img src={ezparking_banner} alt="" loading="lazy" />
        </div>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Explore our EZ Parking management system to manage your parking
              space better and efficiently without the hassles of managing it.
              It works on our Opticsense neural compute platform synchronizing
              with ANPR camera, edge video analytics, mobile apps to make
              parking a breeze. Combining it with our AI excellence award
              winning managed services, we design, build and operate your
              parking space with our EZParking product, letting you focus on
              core works you specialise in. Effortless, easy and paperless
              parking management services from Okulr is a state-of-the-art AI
              based automation solution to make your parking space effective,
              productive & decisive.
            </p>
          </div>
        </div>
        <div className="application_process">
          <ul>
            <li>
              <p>Artificial intelligence enabled parking management system</p>
            </li>
            <hr />
            <li>
              <p>Powered with ANPR edge analytics cameras</p>
            </li>
            <hr />
            <li>
              <p>Integration with boom barriers and automated bollards</p>
            </li>
            <hr />
            <li>
              <p>
                Automated number plate recognition, Vehicle type detection,
                Vehicle counting
              </p>
            </li>
            <hr />
            <li>
              <p>ANPR integrated APP based parking system</p>
            </li>
            <hr />
            <li>
              <p>App, QR, AR based payment gateway for easier payments</p>
            </li>
            <hr />
            <li>
              <p>Optional integration with drive face recognition capture</p>
            </li>
            <hr />
            <li>
              <p>Parking bay availability notification on premises and apps</p>
            </li>
            <hr />
            <li>
              <p>
                Centralised dashboard with data analytics for holistic view of
                parking management
              </p>
            </li>
            <hr />
            <li>
              <p>
                Wealth of EIS & MIS reports for as an affirmative decision
                support system
              </p>
            </li>
            <hr />
          </ul>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>City Wide Parking</li>
              <li>Integrated Parking Complex</li>
              <li>Shopping Malls</li>
              <li>Govt. Secretariat</li>
              <li>Airports</li>
            </ul>
          </div>
        </div>
        {/* <div>
                    <button type="button" className='download_button' onClick={() => { setCatalogForm1(true) }}>Dowload catalogue<img src={cloudDownload} alt="" /></button>
                </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Automated vehicle access control"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <button className="Demo_button_1" onClick={handleDemoScroll}>
          Schedule a demo
        </button>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default EzparkingMysore;
